import React, { useEffect, useState } from 'react'
import './PlansScreen.css'
import { collection, query, where, getDocs, setDoc, doc, addDoc, onSnapshot } from "firebase/firestore";
import { db } from '../../firebase';
import { useSelector } from 'react-redux';
import { selectUser } from '../../features/userSlice';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Oval } from 'react-loader-spinner';
import { getCurrentUser } from '../../features/currentUser';
// tgf
function PlansScreen() {

    const [products, setproducts] = useState([]);
    let user = useSelector(selectUser)// ALSO WENNS n user gibt dann wird anderes screen angezeigt
    const [subscription, setsubscription] = useState(null);
    const [showloading, setshowloading] = useState(false);
    let currentUser = useSelector(getCurrentUser).currentUser?.currentUser

    useEffect(() => {
        const f = async () => {
            const q = query(collection(db, "customers", user.uid, "subscriptions"));
            const docs = await getDocs(q);
            docs.forEach((sub) => {
                //console.log("subß", sub.data())
                setsubscription({
                    role: sub.data().role,
                    current_period_end: sub.data().current_period_end.seconds,
                    current_period_start: sub.data().current_period_start.seconds,
                    cancel_at_period_end: sub.data().cancel_at_period_end,
                    cancel_at: sub.data().cancel_at
                })
            });
        }
        f()
    }, [user.uid]);

    useEffect(() => {
        const f = async () => {
            const q = query(collection(db, "PremsiPremiumSubscription"), where("active", "==", true));
            const docs = await getDocs(q);
            const products = {}
            docs.forEach(async (Productdoc) => {
                products[Productdoc.id] = Productdoc.data()
                const qtwo = query(collection(db, "PremsiPremiumSubscription", Productdoc.id, "prices"));
                const docstwo = await getDocs(qtwo);
                docstwo.forEach((price) => {
                    products[Productdoc.id].prices = { priceId: price.id, priceData: price.data() }
                })
            });
            setproducts(products)
        }
        f()
    }, []);

    const loadCheckout = async (priceId) => {
        //console.log(priceId, window.location.origin)

        const docref = await addDoc(collection(db, "customers", user.uid, "checkout_sessions"), {
            price: priceId,
            success_url: window.location.origin,
            cancel_url: window.location.origin,
        });

        const unsub = onSnapshot(docref, async (snap) => {
            const { error, sessionId } = snap.data()
            //console.log("this is stripesub", error, sessionId)
            if (error) {
                alert("error ocurred", error)
            }
            if (sessionId) {
                // we have a working session so redirect to stripe checkout
                loadStripe.setLoadParameters({ advancedFraudSignals: false });
                const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLEKEY)
                stripe.redirectToCheckout({ sessionId }).then((res) => {
                    //console.log("redirectToCheckout", res)
                    setshowloading(false)
                })
                //console.log(stripe)
            }
        });
        return unsub
    }

    const loadmanagesub = () => {
        // this loads a page where the user needs to put in his email and gets then per email more info
        window.open("https://billing.stripe.com/p/login/dR6aFvaKE79s9Vu144", '_blank', 'noreferrer')
    }
    //here show when unsubcribed when last day
    return (
        <div className='plansscreen'>
            <br />
            {Object.entries(products).map(([productId, productData]) => {
                //add logic to check if user has active subscription
                const iscurrentpackage = productData.role?.toLowerCase() === subscription?.role
                //console.log(iscurrentpackage)
                //console.log(subscription, productData)
                return (
                    <div key={productId} className={`${iscurrentpackage && 'plansscreen__plan--disabled'} plansscreen__plan`}>
                        <div className='plansscreen__info'>
                            <h4>Premsi+</h4>
                            <h5 style={{ color: "black" }}>{productData.description}</h5>
                        </div>
                        {showloading
                            ?
                            <Oval
                                height={40}
                                width={40}
                                color="#009FFD"
                                wrapperStyle={{ flex: "display", alignItems: "center", justifyContent: "center" }}
                                wrapperClass=""
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="white"
                                strokeWidth={5}
                                strokeWidthSecondary={5}
                            />
                            :
                            (currentUser !== null && currentUser !== undefined && currentUser?.premium === true && currentUser?.premiumend > (new Date().getTime() / 1000)
                                ?
                                <button onClick={() => loadmanagesub()}>
                                    Manage Subscription
                                </button>
                                :
                                <button onClick={() => iscurrentpackage ? loadmanagesub() : (setshowloading(true), loadCheckout(productData.prices.priceId))}>
                                    {iscurrentpackage ? 'Manage Subscription' : 'Subscribe 3.49$'}
                                </button>
                            )
                        }
                    </div>
                )
            })}
            <p className="subtext">Please be aware you can cancel the subscription only on the platform you subscribed on.</p>
            {
                subscription &&
                (
                    subscription?.cancel_at_period_end === true
                        ?
                        <p style={{ fontSize: 12, color: "black" }}>Ends automatically at: {new Date(subscription?.cancel_at.seconds * 1000).toLocaleDateString()}</p>
                        :
                        <p style={{ fontSize: 12, color: "black" }}>Renewal Date of Premsi Premium: {new Date(subscription?.current_period_end * 1000).toLocaleDateString()}</p>
                )
            }

        </div >
    )
}

export default PlansScreen
