import { useEffect, useRef, useState } from 'react'
import Hls from 'hls.js'
import { Oval } from 'react-loader-spinner';

export default function VideoPlayerBoughtPM({ src }) {
    const videoRef = useRef(null)
    //console.log("in videplayer hls",src)
    const [loadedvideo, setloadedvideo] = useState(false);
    useEffect(() => {
        const video = videoRef.current
        if (!video) return

        video.controls = true
        if (video.canPlayType('application/vnd.apple.mpegurl')) {
            // This will run in safari, where HLS is supported natively
            //console.log("can play first",src)

            video.src = src
        } else if (Hls.isSupported()) {
            // This will run in all other modern browsers
            //console.log("hls is supported",src)

            const hls = new Hls()
            hls.loadSource(src)
            hls.attachMedia(video)
        } else {
            console.error(
                'This is an old browser that does not support MSE https://developer.mozilla.org/en-US/docs/Web/API/Media_Source_Extensions_API'
            )
            alert("This browser is old and does not support these Videos. Please use newer browsers.")
            video.src = src
        }
    }, [src, videoRef])

    const vidclicked = () => {
        //console.log("video clicked hls")
        videoRef.current.paused ? videoRef.current.play() : videoRef.current.pause()
    }

    useEffect(() => {
        //console.log("loadedvid useeffect", loadedvideo)
    }, [loadedvideo]);

    return (
        <>
            <div style={{ height: "100%", aspectRatio: 12 / 16, borderRadius: 20 }}>
                <video onLoad={() => null} onLoadedData={() => (/*console.log("loaded video")*/ setloadedvideo(true))} controls={true} ref={videoRef} />
                {/* onClick={() => vidclicked()} autoPlay */}
                {loadedvideo ?
                    null
                    :
                    <Oval
                        height={40}
                        width={40}
                        color="#009FFD"
                        wrapperStyle={{ marginTop: 20, flex: "display", alignItems: "center", justifyContent: "center" }}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="white"
                        strokeWidth={5}
                        strokeWidthSecondary={5}
                    />
                }
            </div>
            <style jsx>{`video {max-height: 100%; aspect-ratio: 12/16}`}</style>
        </>
    )
}