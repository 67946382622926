import React, { useState } from 'react'
import Footer from '../../../components/Footer'
import { useNavigate } from 'react-router-dom'
import './index.css'
import { Helmet } from 'react-helmet'
import NavForNotHome from '../../../components/Nav/NavForNotHome';
import FAQs from './FAQs';

function FAQ() {
    const navigate = useNavigate()

    const [faqsone, setfaqsone] = useState([
        {
            question: 'What is Premsi?',
            answer: 'Premsi is the social media where you can see the more private side of your favorite creator. On Premsi, your favorite creators share more through their normal and premium posts. Through donations or buying their premium messages you can support them on their journey. Join their groups to get even more insights.',
            open: true
        },
        {
            question: 'Is Premsi free?',
            answer: 'Yes',
            open: false
        },
        {
            question: 'What is Premsi+?',
            answer: "Premsi+ is our premium subscription for 3.49$ (website). With it you can see the premium posts of every user so every content. It's a all in one subscription. No need anymore to subscribe to every creators premium individually. You can purchase it in our App or on our website.",
            open: false
        },
        {
            question: 'What are premium posts?',
            answer: 'These are posts through which your favorite creator can earn some money as only people with Premsi+ can see them!',
            open: false
        },
        {
            question: 'Will Premsi+ be renewed automatically?',
            answer: 'Yes, but you can cancel it anyday and still have all benefits to the end of your period!',
            open: false
        },
        {
            question: 'How can I cancel Premsi+?',
            answer: 'If subscribed through the App you have to do it in the App or Google Play store. If subcribed over the website, you need to go to your profile and click on the "manage subscription" button.',
            open: false
        },
        {
            question: 'How can I fund my account?',
            answer: "On premsi.com on your profile page you can easily do that with paypal or your credit card! It's immediately. More payment options will come.",
            open: false
        },
        {
            question: 'How do I buy digital content from a creator?',
            answer: 'First you need to fund your account over premsi.com and then just go to the App or on the website to your favorite creator and buy it on their profile! On the website you can also buy it immediately without funding your account or even creating one.',
            open: false
        },
        {
            question: "How do I access the digital content I've purchased?",
            answer: 'You can find it either in the chat with the creator you bought it from or on your profile in the App under "Bought Posts" or in your email inbox if bought without an account!',
            open: false
        },
        {
            question: 'Can digital purchases be refunded?',
            answer: 'Purchases of digital content dont have any return policies, as mostly pictures/videos/pdfs will be sent immediately! In case of scams we will investigate it and 100% refund you. ',
            open: false
        },
        {
            question: 'How can I report suspicious activities or content/scam?',
            answer: 'Just go on the profile or post and block or report it! We will take them serious.',
            open: false
        }
    ]);

    const [faqstwo, setfaqstwo] = useState([
        {
            question: 'What is Premsi?',
            answer: 'Premsi is the first completely monetizable social media on which you can market and sell your digital content at once! Either you can make money by just posting premium posts that will be funded through Premsi+ or you sell your digital content (pics, vids, pdfs) through premium messages. You can also create paid groups that generate you a monthly subscription. Soon we will also add courses, where you can easily create courses and sell and market them through Premsi and paid podcasts.',
            open: true
        },
        {
            question: 'Which content can I post on Premsi and what can I sell?',
            answer: "On Premsi you can post whatever you like and what you are interested in. Sensitive content must be marked as such during the upload process. You can sell whatever content you'd like to.",
            open: false
        },
        {
            question: 'How do I become a creator on Premsi?',
            answer: 'Easy, just register either in the App or on premsi.com and immediately start posting and selling content. No special verification is needed!',
            open: false
        },
        {
            question: 'Is Premsi free for a creator?',
            answer: 'Yes, every feature is completely free! We payout 85% of your earnings in the beginning. The more volume you have the more % you get. As we grow we will also lower this fees.',
            open: false
        },
        {
            question: 'How do I set up premium posts?',
            answer: 'During the post uploading process you can set your upload to be a premium post! Through it you earn money from your supporters.',
            open: false
        },
        {
            question: 'How do I price my digital content?',
            answer: 'You can choose the price yourself!',
            open: false
        },
        {
            question: 'How and when do I get paid for my content sales and premium posts?',
            answer: 'You can payout your earnings through the App via Paypal (soon crypto). Go to the settings and go to "Create Payout". In 2 clicks you can request the payout. Due to Paypal the payout can take up to 3 business days.',
            open: false
        },
        {
            question: 'What do I have to fulfill to create payouts?',
            answer: "You need to connect your paypal account successfully on premsi.com to your account! Please make sure that your paypal account is verified with your email and phone-number otherwise it won't work.",
            open: false
        },
        {
            question: 'How does Premsi ensure the safety and security of my data?',
            answer: "We don't share any data with other parties and don't sell any data! Your private data (e.g. payout data) will be stored on safe servers.",
            open: false
        },
        {
            question: "I'm having trouble accessing my account. What should I do?",
            answer: 'Please retry it on another platform. App or website. We try to achieve 99% uptime.',
            open: false
        },
        {
            question: 'How do I report a bug or provide feedback?',
            answer: 'Please contact us under error@premsi.com. Bonus will be provided for major bugs.',
            open: false
        },
    ]);

    const toggleFAQone = index => {
        setfaqsone(faqsone.map((faq, i) => {
            if (i === index) {
                faq.open = !faq.open
            } else {
                faq.open = false;
            }

            return faq;
        }))
    }

    const toggleFAQtwo = index => {
        setfaqstwo(faqstwo.map((faq, i) => {
            if (i === index) {
                faq.open = !faq.open
            } else {
                faq.open = false;
            }

            return faq;
        }))
    }

    return (
        <>
            <Helmet>
                {/* Open Graph Meta Tags */}
                <meta property="og:title" content="Premsi - Frequently asked questions❤️" />
                <meta property="og:description" content="Premsi | These are the FAQs of Premsi. . Premsi lets you easily sell and market your Videos/Pics/PDFs/Groups and soon courses. All for free and in 1 minute." />
                <meta property="og:url" content="https://premsi.com/faq" />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/premsicom-accb2.appspot.com/o/P1.png?alt=media&token=85d8afdb-d43d-4c28-9c1b-862ea8659fad" />
                <meta property="og:site_name" content="Premsi - sell digital content" />
                <link rel="canonical" href="https://premsi.com/faq" />

                <meta name="description" content="Premsi | Frequently asked questions . Premsi lets you easily sell and market your Videos/Pics/PDFs/Groups and soon courses. All for free and in 1 minute." />

                <title>Premsi - Frequently asked questions</title>
            </Helmet>

            <NavForNotHome />
            <div className='outerlegaldiv' style={{ marginTop: 20 }}>
                <div className='innerlegaldivfaq'>
                    <h1 style={{ textDecoration: "underline", textAlign: "center",fontSize:23 }}>Frequently asked questions - Premsi</h1>

                    <h2 style={{ textDecoration: "underline", textAlign: "center",fontSize:19 }}>For Users:</h2>

                    <div className="faqs">
                        {faqsone.map((faq, i) => (
                            <FAQs faq={faq} index={i} toggleFAQ={toggleFAQone} />
                        ))} 
                    </div>

                    <h2 style={{ textDecoration: "underline", textAlign: "center",fontSize:19 }}>For Creators:</h2>

                    <div className="faqs">
                        {faqstwo.map((faq, i) => (
                            <FAQs faq={faq} index={i} toggleFAQ={toggleFAQtwo} />
                        ))}
                    </div>

                </div>
            </div>

            <Footer />
        </>
    )
}

export default FAQ
