import React, { useEffect, useState } from 'react'
import './Banner.css'
import { getDailyTopOneTrending, getOneRandomPost, getUserProfileInfoWithId } from '../../services/f';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser } from '../../features/currentUser';
import { useSelector } from 'react-redux';

function Banner() {
    const [toptrendingpost, settoptrendingpost] = useState(null);
    const [toptrendinguser, settoptrendinguser] = useState(null);
    const navigate = useNavigate()
    let currentUser = useSelector(getCurrentUser).currentUser?.currentUser

    function truncate(string, n) {
        return string?.length > n ? string.substr(0, n - 1) + ' ...' : string
    }

    useEffect(() => {
        //console.log("load Banner")
        getDailyTopOneTrending().then((post) => {
            //console.log("post1", post)
            if (post !== "empty") {
                settoptrendingpost(post)
                getUserProfileInfoWithId(post[1].creator).then((user) => (settoptrendinguser(user)))
            } else {
                getOneRandomPost(currentUser).then((post) => {
                    //console.log("this is random post", post)
                    settoptrendingpost(post)
                    getUserProfileInfoWithId(post[1].creator).then((user) => (settoptrendinguser(user)))
                })
            }
        })
    }, []);

    return (
        <header className='banner' style={{ backgroundImage: `url(${toptrendingpost ? (toptrendingpost[1]?.type === "image" ? toptrendingpost[1]?.media[0] : toptrendingpost[1]?.media[1]) : null})` }} >
            <div className='banner__contents'>
                <p style={{ fontSize: 26, fontWeight: "bold" }}>{toptrendinguser?.displayName}</p>
                <button className='banner__button' onClick={() => navigate("/post?id=" + toptrendingpost[0])}>See Post</button>
                <button className='banner__button' onClick={() => navigate("/user?id=" + toptrendinguser?.uid)}>Go To User</button>
                {toptrendingpost ? <h2 className='banner__description' style={{ color: "white" }}>{toptrendingpost[1]?.description}</h2> : null}
            </div>
        </header >
    )
}

export default Banner
