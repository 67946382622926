import React, { useEffect, useState } from 'react'
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js'
import $ from 'jquery'
import { httpsCallable } from 'firebase/functions';
import { auth, functions } from '../../firebase';

const PaypalCheckoutButton = ({ product, price }) => {

    // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
    // This is the main reason to wrap the PayPalButtons in a new component
    // this is to rerender screen when user puts in different amount
    //https://paypal.github.io/react-paypal-js/?path=/docs/example-paypalbuttons--default
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
            },
        });
    }, [price]);

    const [paid, setpaid] = useState(false);
    const [pperror, setpperror] = useState(null);
    //console.log("PaypalCheckoutButton", product)

    const handleApprove = async (order) => {
        // call backend to fulfill order and check order
        let orderid = order.id
        let payee = order.purchase_units[0]
        //console.log("in handleApprove", orderid, payee)

        //create cloud func to create these both bc creating not allowed
        await auth.currentUser.getIdToken(true).then((tok) => {
            const checkorderbackend = httpsCallable(functions, 'checkpaypalfunding');
            checkorderbackend({ user: auth.currentUser.uid, order: order, idtoken: tok })
                .then((result) => {
                    // Read result of the Cloud Function.
                    //console.log("resulttt checkorderbackend", result)
                    if (result.data === "success") {
                        //console.log("worked paypal")
                        // if response is success
                        setpperror(null)
                        setpaid(true)
                        //refresh user account or financials
                    } else {
                        //if response is error let user know with alert or so
                        setpperror("there is an error on baclend")
                    }
                })
                .catch((error) => {
                    // Getting the Error details.
                    const code = error.code;
                    const message = error.message;
                    const details = error.details;
                    //console.log("errorcloudfunction", error)
                    //if response is error let user know with alert or so
                    setpperror("there is an error on baclend")
                });
        })
    }
    //experience_context_base: { shipping_preference: 'NO_SHIPPING', brand_name:"Premsi" },

    return (
        <>
            {!paid ?
                <PayPalButtons
                    style={{ shape: "pill", size: 'responsive' }}
                    forceReRender={[product.price]}

                    createOrder={(data, actions) => {
                        $('#paypalmsg').hide();
                        $('#transmsg').show();
                        $('#transmsg').html('<b>' + 'WAITING ON AUTHORIZATION...' + '</b>');
                        $('#chkoutmsg').hide()
                        //console.log("PayPalButtons createorder data", data)
                        return actions.order.create({
                            purchase_units: [{ description: product.description, amount: { value: (product.price) } }],
                            application_context: { shipping_preference: 'NO_SHIPPING' },
                            experience_context_base: { shipping_preference: 'NO_SHIPPING' },
                            paypal_wallet_experience_context: { shipping_preference: 'NO_SHIPPING' }
                        })
                    }}

                    onApprove={async (data, actions) => {
                        //console.log("PayPalButtons onApprove data", data)
                        const order = await actions.order.capture()
                        //console.log("order", order)

                        handleApprove(order)
                    }}

                    onCancel={() => {
                        // display cancel modal or message also with setter useState
                    }}

                    onError={(err) => {
                        //console.log("pp checkout error", err)
                        setpperror(err)
                    }}

                />
                :
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ width: "80%", backgroundColor: "lightgreen", padding: 10, borderRadius: 20, display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <p style={{ color: "darkgreen" }}>Funding your Premsi Account was successful!</p>
                    </div>
                </div>
            }

            {pperror ? <p style={{ textAlign: "center", color: "red" }}>There was an error with the payment. Please retry.</p> : null}
        </>
    )
}

export default PaypalCheckoutButton

