import React, { useEffect, useState } from 'react'
import './SignUpScreenNotHome.css'
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { auth, db, functions } from '../../firebase.js'
import { neo4jcreateUserNode } from '../../services/neo4j.js';
import { httpsCallable } from 'firebase/functions';
import { checkIfUsernameExists, getCurrentUserData, getUserInfoWithId, getUserInfoWithUsername } from '../../services/f.js';
import { Oval } from 'react-loader-spinner'
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

function SignUpScreenNotHome({ setchangedlogin }) {
    const [email, setemail] = useState("");
    const [username, setusername] = useState("");
    const [pw, setpw] = useState("");
    const [acceptedtos, setacceptedtos] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const [usernametaken, setusernametaken] = useState(false);
    const [errorsignup, seterrorsignup] = useState(false);
    const [sthempty, setsthempty] = useState(false);
    const [errorlogin, seterrorlogin] = useState(false);

    const [referralname, setreferralname] = useState("");
    const [referralid, setreferralid] = useState(null);

    const [logintype, setlogintype] = useState(false);

    const [showanimation, setshowanimation] = useState(false);

    const dispatch = useDispatch()

    useEffect(() => {
       // console.log("referralname", referralname)
        if (referralname === null || referralname === undefined) {

        } else {
            getUserInfoWithUsername(referralname).then((referraldoc) => ( setreferralid(referraldoc)))
        }
    }, [referralname]);

    useEffect(() => {
        // homelink so /
        let referral = searchParams.get("referral")
        if (referral === null) {

        } else {
            setreferralname(referral)
        }

    }, []);

    const register = (e) => {
        //console.log("register")
        e.preventDefault();
        setshowanimation(true)
        seterrorlogin(false)
        if (email === "" || username === "" || pw === "") {
            setsthempty(true)
            setshowanimation(false)
            return
        } else {
            setsthempty(false)
        }

        checkIfUsernameExists(username.toLowerCase()).then((res) => {
           // console.log("checkIfUsernameExists")
            if (res.length !== 0) {
                setusernametaken(true)
                setshowanimation(false)
                return
            } else {
                createUserWithEmailAndPassword(auth, email, pw)
                    .then((userCredential) => {
                        // Signed in 
                        const user = userCredential.user;
                        //console.log("this is userid", user.uid)
                        const createWholeUser = httpsCallable(functions, 'createWholeUser');
                        createWholeUser({ user: user.uid, username: username.toLowerCase(), email: email, referralid: referralid === null ? "" : referralid[0], referralname: referralname })
                            .then((result) => {
                                // Read result of the Cloud Function.
                                //console.log("resulttt createWholeUser", result)
                                if (result.data === "success") {
                                   //console.log("worked ot register")
                                    dispatch(getCurrentUserData())
                                    setchangedlogin(true)

                                    neo4jcreateUserNode(username).then(() => {

                                        //create cloud func to create these both bc creating not allowed
                                        const createSubcollectionForUser = httpsCallable(functions, 'createSubcollectionForUser');
                                        createSubcollectionForUser({ user: user.uid })
                                            .then((result) => {
                                                // Read result of the Cloud Function.
                                                //console.log("resulttt createSubcollectionForUser", result)
                                                if (result.data === "success") {
                                                    //console.log("worked ot register")
                                                    setshowanimation(false)
                                                    seterrorsignup(false)
                                                } else {
                                                    seterrorsignup(true)
                                                    setshowanimation(false)
                                                }
                                            })
                                            .catch((error) => {
                                                // Getting the Error details.
                                                const code = error.code;
                                                const message = error.message;
                                                const details = error.details;
                                                //console.log("errorcloudfunction", error)
                                                seterrorsignup(true)
                                                setshowanimation(false)
                                            });
                                    })

                                } else {
                                    seterrorsignup(true)
                                    setshowanimation(false)
                                }
                            })
                            .catch((error) => {
                                // Getting the Error details.
                                const code = error.code;
                                const message = error.message;
                                const details = error.details;
                                //console.log("errorcloudfunctionone", error)
                                seterrorsignup(true)
                                setshowanimation(false)

                            });
                    })
                    .catch((error) => {

                        //console.log(error)
                        // ..
                       // console.log("didnt work to register inner", error.code, error.message, error.details)
                        seterrorsignup(true)
                        setshowanimation(false)
                    });
            }
        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            //console.log(error)
            // ..
            //console.log("didnt work to register", error)
            seterrorsignup(true)
            setshowanimation(false)

        });
    }

    const signIn = (e) => {
        seterrorsignup(false)
        setshowanimation(true)
        //console.log("sign in now", email, pw)
        e.preventDefault();

        if (email === "" || pw === "") {
            setsthempty(true)
            setshowanimation(false)
            return
        } else {
            setsthempty(false)
        }

        signInWithEmailAndPassword(
            auth,
            email,
            pw,
        ).then((authuser) => {
            //console.log("logged in", authuser)
            setshowanimation(false)
            seterrorlogin(false)
            dispatch(getCurrentUserData())
            setchangedlogin(true)
        }).catch((error) => {
            //console.log("err loggingi in", error)
            seterrorlogin(true)
            setshowanimation(false)
        })
    }

    return ( 
        <div className="SignupScreenNotHome">
            <form>
                {!logintype ?
                    <>
                        <input onChange={(e) => setemail(e.target.value)} type="email" placeholder='Email address' />
                        <input onChange={(e) => setpw(e.target.value)} type="password" placeholder='Password' />
                        {showanimation ?
                            <Oval
                                height={40}
                                width={40}
                                color="#009FFD"
                                wrapperStyle={{ flex: "display", alignItems: "center", justifyContent: "center" }}
                                wrapperClass=""
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="white"
                                strokeWidth={5}
                                strokeWidthSecondary={5}
                            />
                            :
                            <button onClick={signIn} type="submit" >Sign In</button>}
                    </>
                    :
                    <>
                        <input onChange={(e) => setusername(e.target.value)} type="text" placeholder='Username' />
                        <input onChange={(e) => setemail(e.target.value)} type="email" placeholder='Email address' />
                        <input onChange={(e) => setpw(e.target.value)} type="password" placeholder='Password' />
                        <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "start" }}>
                            <input onChange={() => setacceptedtos(!acceptedtos)} type="checkbox" id="scales" name="scales" checked={acceptedtos} />
                            <label for="scales" style={{ marginLeft: 5, fontSize: 12 }}>Accept <a href="termsofservice" style={{ color: "black" }}>Terms of Service</a></label>
                        </div>
                        {showanimation
                            ?
                            <Oval
                                height={40}
                                width={40}
                                color="#009FFD"
                                wrapperStyle={{ flex: "display", alignItems: "center", justifyContent: "center" }}
                                wrapperClass=""
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="white"
                                strokeWidth={5}
                                strokeWidthSecondary={5}
                            />
                            :
                            (acceptedtos ? <button onClick={register} type="submit" >Create Account</button> : <div className="accepttosbutton" >Please accept TOS</div>)}
                        {searchParams.get("referral") === null
                            ?
                            null
                            :
                            (referralid === "empty"
                                ?
                                <h6 style={{ color: "gray" }}>Referral doesn't exist!</h6>
                                :
                                <h6 style={{ color: "gray" }}>Your referral: {referralname}</h6>)
                        }
                    </>
                }
                {usernametaken ? <p style={{ textAlign: "center", color: "red", fontSize: 12 }}>Username taken! Choose another</p> : null}
                {errorsignup ? <p style={{ textAlign: "center", color: "red", fontSize: 12 }}>There was an error signin up. Check valid e-mail.</p> : null}
                {sthempty ? <p style={{ textAlign: "center", color: "red", fontSize: 12 }}>Fill out everything.</p> : null}
                {errorlogin ? <p style={{ textAlign: "center", color: "red", fontSize: 12 }}>There was an error loging in. Check password/email.</p> : null}

                <h5>
                    <span style={{ color: "gray", marginRight: 5, fontSize: 13, textAlign:"center" }}>{logintype === false ? "New to Premsi?" : "Already have an account?"}</span>
                    <span className="SignupScreen__link" style={{ fontSize: 13, textAlign:"center" }} onClick={() => (setpw(""), setusername(""), setemail(""), setlogintype(!logintype))}>{logintype === false ? "Sign Up now." : "Sign In."}</span>
                </h5>
            </form>
        </div>
    )
}

export default SignUpScreenNotHome
