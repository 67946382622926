import React from 'react'
import { useNavigate } from 'react-router-dom'
import './index.css'
import { TwitterShareButton } from 'react-twitter-embed'
import TrustBox from '../../screens/Legal/Trustpilot/trustpilot'

export default function Footer() {
  const navigate = useNavigate()

  return (
    <div className='footer_whole_home'>
      <div className='footer_middle_home'>
        <img loading="lazy" onClick={() => navigate("/")} className="nav__logo_home" src={require('../../images/pblue-min.webp')} alt="Premsi.com logo app icon" />
        <a style={{ cursor: "pointer", textAlign: "center" }} onClick={() => navigate("/faq")} >FAQ</a>
        <a style={{ cursor: "pointer", textAlign: "center" }} onClick={() => navigate("/privacypolicy")} >Privacy Policy</a>
        <a style={{ cursor: "pointer", textAlign: "center" }} onClick={() => navigate("/termsofservice")} >Terms of service</a>
        <a style={{ cursor: "pointer", textAlign: "center" }} onClick={() => navigate("/communityguidelines")} >Community Guidelines</a>
        <a style={{ cursor: "pointer", textAlign: "center" }} href="https://blog.premsi.com">Blog</a>

        <div className='socialsfooter'>
          <a href="https://www.google.com" style={{ cursor: "pointer", display: "flex", alignItems: "center" }} target='_blank'><img loading="lazy" alt='premsi instagram link' style={{ width: 30, borderRadius: 15, height: 30 }} src={require('../../images/socialicons/instalogo.webp')} /></a>
          <a href="https://www.twitter.com/PremsiCom" style={{ cursor: "pointer", display: "flex", alignItems: "center" }} target='_blank'><img loading="lazy" alt='premsi twitter link' style={{ width: 30, borderRadius: 15, height: 30, }} src={require('../../images/socialicons/twitter.webp')} /></a>
          <TwitterShareButton
            onLoad={function noRefCheck() { }}
            options={{
              buttonHashtag: undefined,
              screenName: undefined,
              size: 'large',
              text: "Have a look at Premsi: The most monetizable social media App 🔥🧿",
              //via: 'saurabhnemade'
            }}
            placeholder={<div style={{ backgroundColor: 'transparent', color: 'white', margin: 10, padding: 10, textAlign:"center",fontSize:11 }}>loading x</div>}
            url={`https://www.premsi.com/`}
          />
        </div>
        <a style={{ cursor: "pointer", textAlign: "center" }} href="mailto:contact@premsi.com">Contact</a>
        <a style={{ cursor: "pointer", textAlign: "center" }} href="mailto:partner@premsi.com?subject=Partner request&body=Hi I would like to work with you...">Become a Partner</a>
      </div>

      <div className='footer_middle_home'>
        <a style={{ cursor: "pointer", textAlign: "center" }} onClick={() => navigate("/presentation")} >Premsi presentation</a>
        <a style={{ cursor: "pointer", textAlign: "center" }} onClick={() => navigate("/explanation")} >How to use the App?</a>
        <a style={{ cursor: "pointer", textAlign: "center" }} onClick={() => navigate("/calculator")} >Earnings calculator</a>
        <a style={{ cursor: "pointer", textAlign: "center" }} onClick={() => navigate("/createpremiummessage")} >Create Premium Message</a>
        <a style={{ cursor: "pointer", textAlign: "center" }} onClick={() => navigate("/sell")} >Sell</a>
      </div>
      {/*<TrustBox />*/}
      <p style={{ color: "white", fontWeight: "bold", textAlign: "center", fontSize: 12, marginTop: 5 }}>© 2024 Copyright Premsi</p>
    </div>
  )
}
