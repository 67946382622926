import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../features/userSlice';
import currentUserReducer from '../features/currentUser';

export const store = configureStore({
  reducer: {
    user: userReducer,
    currentUser: currentUserReducer //this name doesnt exist in features/currentUser but is absically the object from line 26
  },
});

// how create redux object:
//      look into example in features/currentUser + here create store instance for it like in line 8 + 
// to get info from redux: 
//      let currentUser = useSelector(getCurrentUser)// ALSO WENNS n user gibt dann wird anderes screen angezeigt
//      console.log("this is currentusr",currentUser)
// to dispatch info into redux store:
//      dispatch(getCurrentUserData()) + check function getCurrentUserData() here dispatch info into store