import React, { Component, useEffect, useState } from "react";
import { Stripe, CardElement, injectStripe, Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, updateDoc } from "firebase/firestore";
import { auth, db, functions } from "../../../firebase";
import { MdDelete } from "react-icons/md";
import { useSearchParams } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { Oval } from "react-loader-spinner";
function CardRow({ card, index, setchosen, chosen, showCards }) {
  //console.log("in CardRow", card)

  return (
    <div style={{ borderRadius: 15, height: 40, color: "black", width: "90%", margintop: 10, marginBottom: 10, display: "flex", alignItems: "center", justifyContent: "space-around", flexDirection: "row", borderStyle: "solid", borderColor: "#009ffd" }}>
      <p>**** **** **** {card.card.last4}</p>
      <p>Expire: {card.card.exp_month}/{card.card.exp_year}</p>
      <div onClick={() => setchosen(index)} style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingTop: 4, paddingLeft: 10, paddingRight: 10, paddingBottom: 4, borderRadius: 10, backgroundColor: (chosen === index ? "#009ffd" : "lightblue") }}>
        <p style={{ fontSize: 13, color: "white" }}>Choose</p>
      </div>
    </div>
  )
}


function PayWithSavedCards({ stripe, elements, amount }) {
  const [errorMessage, seterrorMessage] = useState();
  const [successmessage, setsuccessmessage] = useState();
  const [em, setem] = useState("");
  // get saved cards
  const [cards, setcards] = useState([]);
  // get payments
  const [nopayments, setnopayments] = useState(false);
  const [payments, setpayments] = useState([]);
  const [chosen, setchosen] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setloading] = useState(false);
  // for delay
  const delay = ms => new Promise(res => setTimeout(res, ms));

  useEffect(() => {
    seterrorMessage()
    showCards()

  }, []);

  // Format amount for Stripe
  function formatAmountForStripe(amount, currency) {
    return zeroDecimalCurrency(amount, currency)
      ? amount
      : Math.round(amount * 100);
  }
  // Check if we have a zero decimal currency
  // https://stripe.com/docs/currencies#zero-decimal
  function zeroDecimalCurrency(amount, currency) {
    let numberFormat = new Intl.NumberFormat(['en-US'], {
      style: 'currency',
      currency: currency,
      currencyDisplay: 'symbol',
    });
    //console.log("numberformat", numberFormat)
    const parts = numberFormat.formatToParts(amount);
    let zeroDecimalCurrency = true;
    for (let part of parts) {
      if (part.type === 'decimal') {
        zeroDecimalCurrency = false;
      }
    }
    return zeroDecimalCurrency;
  }

  const showCards = () => {
    setpayments([])
    setcards([])
    console.log("show cards")
    let a = []

    const f = async () => {
      const q = query(collection(db, "stripe_customers", auth.currentUser.uid, "payment_methods"));
      const docs = await getDocs(q);
      if (docs.empty) {
        return
      } else {

        docs.forEach(async (doc) => {
          console.log("first", doc.data(), !doc.data().card)
          if (!doc.data().card) {
            return
          } else {
            let obj = { ...doc.data(), firebaseid: doc.id }
            a.push(obj)
          }
        })
        setcards(a)
      }
    }

    f()
  }

  const createTransaction = async () => {
    seterrorMessage()
    setsuccessmessage()
    let paymentmethod = cards[chosen]
    const currency = "USD"
    setloading(true)

    const Ref = collection(db, 'stripe_customers', auth.currentUser.uid, "payments");
    addDoc(Ref, {
      payment_methodfirst: paymentmethod,
      currency,
      amount: formatAmountForStripe(amount, currency),
      status: 'new',
      return_url: window.location.href //set this in production to premsi.com/profile
    }).then(async (docRef) => {
      console.log("created transaction", docRef.id)

      await delay(5000)
      console.log("now wait some seconds and then retrieve object to get status from payment")
      //here do handleCardAction get the doc again with docRef.id and then handlecaradaction and there also check status of payment like in useeffect above

      checkPaymentStatus(docRef.id)
      console.log("created transaction as soon as succeeded the bought message will be sent out or the premiummessage will be sent or joinedgroup, etc")
    })
  }
  // A `return_url` must be specified because this Payment Intent is configured to automatically accept the payment methods enabled in the Dashboard, some of which may require a full page redirect to succeed. If you do not want to accept redirect-based payment methods, set `automatic_payment_methods[enabled]` to `true` and `automatic_payment_methods[allow_redirects]` to `never` when creating Setup Intents and Payment Intents.

  async function checkPaymentStatus(id) {
    await delay(5000)
    const q = doc(db, "stripe_customers", auth.currentUser.uid, "payments", id);
    getDoc(q).then((res) => {
      if (res.exists) {
        console.log("checkPaymentStatus", res.data().status)

        if (res.data().status === "requires_action") {
          handleCardAction(res.data(), res.id);

        } else if (res.data().status === "requires_confirmation") {
          console.log("still confirming in background")
          //setloading(false)
          setsuccessmessage("It is confirming the Payment.")
          checkPaymentStatus(id)

        } else if (res.data().status === "card_declined") {
          setloading(false)
          seterrorMessage("Your card got declined! Possible: Insufficient Funds, Blocked, ")

        } else if (res.data().status === "succeeded") {
          console.log("payment succeeded")
          setloading(false)
          setsuccessmessage("Your payment got approved. Your Account will be funded soon!")

        } else {
          console.log("payment status", res.data().status)
          setloading(false)
          seterrorMessage("Your card had an issue!")

        }
      } else {
        setnopayments(true)
        return
      }
    });
  }

  // Handle card actions like 3D Secure
  async function handleCardAction(payment, docId) {
    console.log("handlecardaction", payment.client_secret)
    const { error, paymentIntent } = await stripe.handleCardAction(
      payment.client_secret
    )

    if (error) {
      alert(error.message);
      payment = error.payment_intent;
    } else if (paymentIntent) {
      payment = paymentIntent;
    }

    console.log("thos is payment in handleCardAction", payment)
    const Ref = collection(db, 'stripe_customers', auth.currentUser.uid, "payments", docId);
    await updateDoc(Ref, {
      payment
    }).then((res) => {
      console.log("handleCardActio", res)
    })
  }

  return (
    <form style={{ alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column" }}>

      {cards.length !== 0 ?
        <div style={{ width: "100%", marginTop: 15, maxHeight: 400, overflow: "scroll", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          {cards.map((card, i) => (
            <CardRow card={card} index={i} setchosen={setchosen} chosen={chosen} showCards={showCards} />
          ))}
          {loading
            ?
            <Oval
              height={40}
              width={40}
              color="#009FFD"
              wrapperStyle={{ flex: "display", alignItems: "center", justifyContent: "center" }}
              wrapperClass=""
              visible={true}
              ariaLabel='oval-loading'
              secondaryColor="white"
              strokeWidth={5}
              strokeWidthSecondary={5}
            />
            :
            <div style={{ color: "white", backgroundColor: "#009ffd", paddingTop: 5, paddingBottom: 5, paddingLeft: 20, paddingRight: 20, borderRadius: 10 }} onClick={() => createTransaction()}>
              Fund
            </div>}
        </div>
        :
        <p style={{ color: "red", marginTop: 15, fontSize: 14 }}>You don´t have any saved cards yet! Save them in your profile.</p>
      }

      {nopayments ? <p style={{ color: "red", marginTop: 15, fontSize: 14 }}>You don´t have any payments yet</p> : null}
      {errorMessage ? <p style={{ color: "red", marginTop: 15, fontSize: 14 }}>{errorMessage}</p> : null}
      {successmessage ? <p style={{ color: "green", marginTop: 15, fontSize: 14 }}>{successmessage}</p> : null}
    </form>
  );

}

export default PayWithSavedCards;