import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Nav from '../../components/Nav/Nav'
import PlansScreen from '../../components/PlansScreen/PlansScreen'
import { selectUser } from '../../features/userSlice'
import { auth, db } from '../../firebase'
import './ProfileScreen.css'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getCurrentUser } from '../../features/currentUser'
import { Switch } from '@mui/material'
import { connectpaypaltouser, doEmailVerification, getCurrentBalanceOfUser, getCurrentUserData, updateSensitive } from '../../services/f'
import { ColorRing, Oval } from 'react-loader-spinner'
import PaypalCheckoutButton from '../../components/PaypalCheckoutButton/PaypalCheckoutButton'
import { PiUserCircleLight } from 'react-icons/pi'
import Footer from '../../components/Footer'
import VerifyEmailComponent from '../../components/rememberverifyemail'
import { Helmet } from 'react-helmet'
import { loadStripe } from '@stripe/stripe-js';
import {
    PaymentElement,
    Elements,
    useStripe,
    useElements,
    ElementsConsumer,
} from '@stripe/react-stripe-js';
import SaveCardForm from './StripeFolder/SaveCardForm'
import { collection, doc, getDoc, updateDoc } from 'firebase/firestore'
import { stripePromise } from '../../App'
import PayElementStripePremsi from './StripeFolder/PayElementStripePremsi'
import PayWithSavedCards from './StripeFolder/PayWithSavedCards'

const InjectedSaveCardForm = () => (
    <ElementsConsumer>
        {({ stripe, elements, stripeuser }) => (
            <SaveCardForm stripe={stripe} elements={elements} />
        )}
    </ElementsConsumer>
);

const InjectedPayWithSavedCard = ({ amount }) => (
    <ElementsConsumer>
        {({ stripe, elements, stripeuser }) => (
            <PayWithSavedCards stripe={stripe} elements={elements} amount={amount} />
        )}
    </ElementsConsumer>
);

const delay = ms => new Promise(res => setTimeout(res, ms));

function ProfileScreen() {
    let currentUser = useSelector(getCurrentUser).currentUser?.currentUser// ALSO WENNS n user gibt dann wird anderes screen angezeigt
    const dispatch = useDispatch()

    //console.log("this is currentusr", currentUser)
    const [sensitive, setsensitive] = useState(currentUser === null || currentUser === undefined ? false : currentUser?.sensitive);
    const [emailverrequested, setemailverrequested] = useState(false);
    const [balance, setbalance] = useState(-1);
    const [searchParams, setSearchParams] = useSearchParams();
    const [showpaypalpopup, setshowpaypalpopup] = useState(true);
    const [fundaccountshowmore, setfundaccountshowmore] = useState(false);
    const [connectingpp, setconnectingpp] = useState(false);
    const [connectingdone, setconnectingdone] = useState(false);
    const [connectingerror, setconnectingerror] = useState(false);
    const [copiedreferral, setcopiedreferral] = useState(false);
    const [copiedown, setcopiedown] = useState(false);
    const [copiedsecown, setcopiedsecown] = useState(false);
    const [addpaymentmethodshowmore, setaddpaymentmethodshowmore] = useState(false);

    const [paymentsuccess, setpaymentsuccess] = useState(false);
    const [paymentverifying, setpaymentverifying] = useState(false);

    const [fundacc, setfundacc] = useState(false);
    const [fundamount, setfundamount] = useState(0);
    const [paymenttype, setpaymenttype] = useState(0);

    const stripe = useStripe()

    const user = useSelector(selectUser)
    const navigate = useNavigate()

    const product = {
        description: "Fund Account - Premsi - Digital Good funded immediately",
        price: fundamount,
        category: "DIGITAL_GOODS"
    }

    useEffect(() => {
        setsensitive(currentUser?.sensitive)
    }, [currentUser]);

    useEffect(() => {
        // to check if got sth back from stripe payment and now verify it
        const f = async () => {
            let piclientsecret = searchParams.get("payment_intent_client_secret")
            let docid = searchParams.get("docid")
            console.log("useefect for clientsecret", piclientsecret, docid)

            if (piclientsecret === null || piclientsecret === undefined || piclientsecret === "") {
                console.log("nothing", piclientsecret, docid)
                return
            } else {
                console.log("exists so", docid, piclientsecret)
                const { paymentIntent } = await stripe.retrievePaymentIntent(piclientsecret);
                console.log("paymentintent", paymentIntent, docid)

                if (paymentIntent && paymentIntent.status === 'requires_confirmation') {
                    console.log("paymentintent", paymentIntent)
                    //here add paymentintent to object
                    console.log("thos is payment in handleCardAction", paymentIntent)
                    const Ref = doc(db, 'stripe_customers', auth.currentUser.uid, "payments", docid);
                    let payment = paymentIntent
                    await updateDoc(Ref, {
                        status: payment.status
                    })
                    setpaymentverifying(true)
                    await delay(10000)
                    verifying()
                } else if (paymentIntent && paymentIntent.status === 'succeeded') {
                    console.log("paymentintent", paymentIntent)
                    setpaymentsuccess(true)
                    setpaymentverifying(false)
                    await delay(5000)
                    window.location.href = "https://www.premsi.com/profile"
                }
            }
        }
        f()
    }, []);

    useEffect(() => {
        //console.log("page profile reloaded")
        window.paypal.use(['login'], function (login) {
            login.render({
                "appid": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                "authend": "live",
                "scopes": "profile email https://uri.paypal.com/services/paypalattributes",
                "containerid": "paypal-button",
                "responseType": "code",
                "locale": "en-us",
                "buttonType": "CWP",
                "buttonShape": "rectangle",
                "buttonSize": "md",
                "fullPage": "true",
                "returnurl": "https://www.premsi.com/profile"
            })
        });
    });

    const verifying = async () => {
        let piclientsecret = searchParams.get("payment_intent_client_secret")
        let docid = searchParams.get("docid")
        console.log("useefect for clientsecret", piclientsecret, docid)

        if (piclientsecret === null || piclientsecret === undefined || piclientsecret === "") {
            console.log("nothing", piclientsecret, docid)
            return
        } else {
            console.log("exists so", docid, piclientsecret)
            const { paymentIntent } = await stripe.retrievePaymentIntent(piclientsecret);
            console.log("paymentintent", paymentIntent, docid)

            if (paymentIntent && paymentIntent.status === 'requires_confirmation') {
                console.log("paymentintent", paymentIntent)
                //here add paymentintent to object
                console.log("thos is payment in handleCardAction", paymentIntent)
                const Ref = doc(db, 'stripe_customers', auth.currentUser.uid, "payments", docid);
                let payment = paymentIntent
                await updateDoc(Ref, {
                    status: payment.status
                })
                setpaymentverifying(true)
                await delay(10000)
                verifying()
                // Handle successful payment here
            } else if (paymentIntent && paymentIntent.status === 'succeeded') {
                // Handle unsuccessful, processing, or canceled payments and API errors here
                console.log("paymentintent", paymentIntent)
                setpaymentsuccess(true)
                setpaymentverifying(false)
                await delay(5000)
                window.location.href = "https://www.premsi.com/profile"

            } else {

            }
        }

    }

    useEffect(() => {
        // check if code available then show paypalpup showpaypalpopup
        //console.log("this is searcharams", searchParams.get("code"), currentUser?.paypalconnected === false && searchParams.get("code") !== null && searchParams.get("code") !== "" && searchParams.get("code") !== undefined)
        if (searchParams.get("code") !== null && searchParams.get("code") !== "" && searchParams.get("code") !== undefined) {
            //console.log("should show the paypal one")
            setshowpaypalpopup(false)
        }
    }, [searchParams]);

    const changesensitive = (e) => {
        e.preventDefault()
        if (!sensitive !== currentUser?.sensitive && currentUser?.sensitive !== null) {
            //console.log("change sensitive for user")
            updateSensitive(auth.currentUser.uid, !currentUser?.sensitive).then(() => {
                //console.log("done changing")
                dispatch(getCurrentUserData())
            })
        }
        setsensitive(!sensitive)
    }

    const getCurrentBalance = (e) => {
        e.preventDefault()
        //console.log("get current balance")
        getCurrentBalanceOfUser().then((res) => {
            //console.log(res)
            setbalance(res.balance)
        })
    }

    const verifyemail = (e) => {
        e.preventDefault()
        if (emailverrequested === false && (currentUser?.emailversent === undefined || currentUser.emailversent === 0 || currentUser.emailversent === null || ((currentUser.emailversent.seconds + 86400) * 1000) <= new Date())) {
            doEmailVerification().then(() => setemailverrequested(true))
        } else {
            setemailverrequested(true)
        }
    }

    const connectpaypal = (e) => {
        e.preventDefault()
        //console.log("starting connecting")
        setconnectingpp(true)
        connectpaypaltouser(searchParams.get("code")).then((res) => {
            //console.log("done connecting", res)
            if (res === "success") {
                setconnectingpp(false)
                setconnectingdone(true)
            } else {
                setconnectingpp(false)
                setconnectingerror(true)
            }
        })
    }
    let titledescription = "Hi " + currentUser?.displayName + ", here you can edit your profile." + "Sell and market digital content in seconds. Download the Premsi App for more features. Premsi lets you easily sell and market your Videos/Pics/PDFs/Groups and soon courses. All for free and in 1 minute."
    let titlet = "Premsi - Your profile: " + currentUser?.displayName
    return (
        <div className='profilescreen'>
            <Helmet>
                {/* Open Graph Meta Tags */}
                <meta property="og:title" content="Premsi | Profile - Sell and market digital content in seconds" />
                <meta property="og:description" content="Premsi | Sell and market digital content in seconds. Download the Premsi App for more features. Premsi lets you easily sell and market your Videos/Pics/PDFs/Groups and soon courses. All for free and in 1 minute." />
                <meta property="og:url" content="https://premsi.com/profile" />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/premsicom-accb2.appspot.com/o/P1.png?alt=media&token=85d8afdb-d43d-4c28-9c1b-862ea8659fad" />
                <meta property="og:site_name" content="Premsi - sell digital content" />
                <link rel="canonical" href="https://premsi.com/profile" />

                <title>{titlet}</title>
                <meta name="description" content={titledescription} />
            </Helmet>
            <Nav />
            {auth.currentUser === null || auth.currentUser === undefined || auth?.currentUser?.emailVerified === true ? null : <VerifyEmailComponent />}

            {paymentsuccess ?
                <div style={{ height: 40, width: "100%", backgroundColor: "green", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <p style={{ color: "white", fontSize: 14 }}>Payment Verified!</p>
                </div>
                : null
            }

            {paymentverifying ?
                <div style={{ height: 40, width: "100%", backgroundColor: "green", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <p style={{ color: "white", fontSize: 14 }}>Payment being verified now!</p>
                </div>
                : null
            }

            <div className='profilescreen__body'>
                <div className='profiletop'>
                    <p className='editprofileheading' onClick={() => navigate("/user?id=" + auth.currentUser.uid)}>Edit Profile: {currentUser?.displayName}</p>
                    <div>
                        <div onClick={() => (navigator.clipboard.writeText(`https://premsi.com/user?id=${auth.currentUser.uid}`), setcopiedown(true))} style={{ marginBottom: 7, paddingRight: 20, cursor: "pointer", color: "white", paddingLeft: 20, paddingTop: 7, paddingBottom: 7, borderRadius: 15, backgroundColor: "#009FFD", textAlign: "center", display: "flex", justifyContent: "center" }}>
                            {copiedown ?
                                "Copied 🎉" :
                                "Share your Profile 🎉"
                            }
                        </div>
                        <div onClick={() => (navigator.clipboard.writeText(`https://premsi.com/user?id=${auth.currentUser.uid}&t=p`), setcopiedsecown(true))} style={{ marginTop: 7, marginBottom: 7, paddingRight: 20, cursor: "pointer", color: "white", paddingLeft: 20, paddingTop: 7, paddingBottom: 7, borderRadius: 15, textAlign: "center", backgroundColor: "#009FFD", display: "flex", justifyContent: "center" }}>
                            {copiedsecown ?
                                "Copied 🎉" :
                                "Share your Premium Messages 🎉"
                            }
                        </div>
                        <div onClick={() => navigate("/createpremiummessage")} style={{ marginTop: 7, marginBottom: 12, paddingRight: 20, cursor: "pointer", color: "white", paddingLeft: 20, paddingTop: 7, paddingBottom: 7, borderRadius: 15, backgroundColor: "lightgreen", display: "flex", justifyContent: "center" }}>
                            Sell with Premsi 💰
                        </div>
                    </div>
                </div>

                <div className='profilescreen__info'>

                    {currentUser?.photoUrl === "" || currentUser === null || currentUser === undefined
                        ?
                        <PiUserCircleLight className='avatar_nopic' size={38} color='black' alt='no profile picture' />
                        :
                        <img loading="lazy" style={{ pointerEvents: "none" }} className='avatar' src={currentUser?.photoUrl} alt="user profile pic" />
                    }

                    <div className='profilescreen__details'>
                        <h2 className='changeinfoinapp' onClick={() => navigate("/app")}>To change more information please download our App.</h2>
                        <div className='emaildiv'>
                            <h2 className='profileemail'>{user.email}</h2>
                            {
                                auth.currentUser.emailVerified === false
                                    ?
                                    <button onClick={verifyemail} style={{ border: 0, backgroundColor: "red", borderRadius: 15, color: "white", paddingLeft: 10, paddingRight: 10, height: 30 }}>
                                        {emailverrequested ? "E-Mail sent!" : "Verify your e-mail!"}
                                    </button>
                                    : null
                            }
                        </div>

                        {currentUser === undefined ?
                            <div>
                                <Oval
                                    height={40}
                                    width={40}
                                    color="#009FFD"
                                    wrapperStyle={{ marginTop: 20, flex: "display", alignItems: "center", justifyContent: "center" }}
                                    wrapperClass=""
                                    visible={true}
                                    ariaLabel='oval-loading'
                                    secondaryColor="white"
                                    strokeWidth={5}
                                    strokeWidthSecondary={5}
                                />
                                <p style={{ marginBottom: 3, textAlign: "center" }}>Refreshing user...</p>
                                <p style={{ textAlign: "center", fontSize: 12 }}>If this takes too long please refresh this window.</p>
                            </div>
                            :
                            <>
                                <div className={!fundaccountshowmore ? "fundingrowoff" : "fundingrowon"}>
                                    <div className='inrow'>
                                        <div style={{ cursor: "pointer", flex: 1, alignItems: "center" }}>
                                            <h3>Fund Account</h3>
                                            {!fundacc ? null : <p style={{ color: "red", fontSize: 12 }}>Min. funding amount is 1$.</p>}
                                        </div>
                                        <div className='fundingbtns' style={{ display: "grid", alignItems: "center", justifyContent: "center" }}>
                                            <button onClick={getCurrentBalance} style={{ cursor: "pointer", color: "darkgreen", paddingLeft: 10, paddingRight: 10, fontWeight: "bold", border: "0", borderRadius: 15, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "lightgreen", height: 30 }}>
                                                <p style={{ fontSize: 12, color: "darkgreen" }}>Get current balance {balance === -1 ? null : `${balance}$`}</p>
                                            </button>
                                            {fundaccountshowmore
                                                ?
                                                <button style={{ marginTop: 15, right: 0, height: 30, paddingLeft: 10, paddingRight: 10, border: "0", borderRadius: 15, color: "white", backgroundColor: "gray", fontWeight: "bold" }} onClick={() => (setfundamount(0), setfundaccountshowmore(false))}>Close</button>
                                                :
                                                <button style={{ marginTop: 15, height: 30, paddingLeft: 10, paddingRight: 10, border: "0", borderRadius: 15, color: "white", backgroundColor: "#009FFD", fontWeight: "bold" }} onClick={() => setfundaccountshowmore(true)}>Fund Account</button>
                                            }
                                        </div>
                                    </div>

                                    {fundaccountshowmore
                                        ?
                                        ((Number(currentUser?.createdAt.seconds) + (5 * 60)) > (Math.floor(new Date().getTime() / 1000)) || currentUser === undefined
                                            ?
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <p style={{ textAlign: "center", width: "90%", fontSize: 14, color: "red" }}> Your Account needs to be atleast 5 minutes old in order to be able to fund it with money.</p>
                                            </div>
                                            :
                                            <div className={fundaccountshowmore ? 'fadeIn' : 'fadeout'}>
                                                <p style={{ textAlign: "center" }}>Please select an amount min. 5$</p>
                                                <div style={{ display: "flex", marginTop: 10, marginBottom: 10, alignItems: "center", justifyContent: "center" }}>
                                                    <input style={{ borderStyle: "solid", borderColor: "#009ffd", color: "black", fontSize: 17, borderRadius: 10, height: 30, textAlign: "center", width: 100, backgroundColor: "rgba(255,255,255,0.4)" }} onChange={(e) => setfundamount(Number(e.target.value))} type="number" placeholder='$' />
                                                </div>
                                                {fundamount < 5
                                                    ?
                                                    <p style={{ textAlign: "center", color: "red", fontSize: 14, marginBottom: 10 }}>Please input atleast 5$.</p>
                                                    :
                                                    <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                                                        <div onClick={() => setpaymenttype(1)} style={{ width: 120, height: 30, borderRadius: 15, backgroundColor: "#009ffd", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                            PayPal
                                                        </div>
                                                        <div onClick={() => setpaymenttype(3)} style={{ width: 120, height: 30, borderRadius: 15, backgroundColor: "#009ffd", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                            Saved Cards
                                                        </div>
                                                        <div onClick={() => setpaymenttype(2)} style={{ width: 120, height: 30, borderRadius: 15, backgroundColor: "#009ffd", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                            Credit Card
                                                        </div>
                                                    </div>
                                                }
                                                {paymenttype === 0 ?
                                                    null :
                                                    (paymenttype === 1
                                                        ?
                                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                            <div className='paypal-button-container' style={{ marginTop: 15, width: "60%" }}>
                                                                <PaypalCheckoutButton product={product} price={fundamount} />
                                                            </div>
                                                        </div>
                                                        :
                                                        (paymenttype === 2
                                                            ?
                                                            <div style={{ marginTop: 15 }}>
                                                                <Elements stripe={stripePromise} options={{ mode: "payment", amount: 122, currency: "usd" }}>
                                                                    <PayElementStripePremsi amount={fundamount} />
                                                                </Elements>
                                                            </div>
                                                            :
                                                            <InjectedPayWithSavedCard amount={fundamount} />)
                                                    )
                                                }
                                            </div>)
                                        :
                                        null
                                    }
                                </div>

                                <div className={!addpaymentmethodshowmore ? "fundingrowoff" : "fundingrowon"}>
                                    <div className='inrow'>
                                        <div style={{ cursor: "pointer", flex: 1, alignItems: "center" }}>
                                            <h3>Add Payment Method</h3>
                                        </div>
                                        <div className='fundingbtns' style={{ display: "grid", alignItems: "center", justifyContent: "center" }}>

                                            {addpaymentmethodshowmore
                                                ?
                                                <button style={{ marginTop: 15, right: 0, height: 30, paddingLeft: 10, paddingRight: 10, border: "0", borderRadius: 15, color: "white", backgroundColor: "gray", fontWeight: "bold" }} onClick={() => (setaddpaymentmethodshowmore(false))}>Close</button>
                                                :
                                                <button style={{ marginTop: 15, height: 30, paddingLeft: 10, paddingRight: 10, border: "0", borderRadius: 15, color: "white", backgroundColor: "#009FFD", fontWeight: "bold" }} onClick={() => setaddpaymentmethodshowmore(true)}>Add Card</button>
                                            }
                                        </div>
                                    </div>

                                    {addpaymentmethodshowmore
                                        ?
                                        <>
                                            <InjectedSaveCardForm />
                                        </>
                                        :
                                        null
                                    }



                                </div>

                                <div className='inrow'>
                                    <div style={{ flex: 1, alignItems: "center" }}>
                                        <h3>Sensitive</h3>
                                        <p style={{ marginTop: 7, fontSize: 12, color: "black" }}>{sensitive ? "You can see sensitive content" : "You don't see sensitive content."}</p>
                                    </div>
                                    {
                                        currentUser?.settedsensitive !== undefined && (Number(currentUser?.settedsensitive?.seconds) + (60 * 60 * 24)) > (Math.floor(new Date().getTime() / 1000))
                                            ?
                                            <p className='senstextbtn' style={{ color: "red", fontSize: 12, width: 160, textAlign: "center" }}>Possible to change only once per day</p>
                                            :
                                            <button className='senstextbtn' type='button' style={{ border: "0", width: 70, borderRadius: 15, height: 30, backgroundColor: "rgba(255,255,255,0.3)", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <Switch size='big' color='success' checked={sensitive} onChange={changesensitive} />
                                            </button>
                                    }
                                </div>

                                <div className='inrow'>
                                    <div style={{ flex: 1, alignItems: "center" }}>
                                        <h3>Create a premium message:</h3>
                                        <p style={{ marginTop: 7, fontSize: 12, color: "black" }}>Easily market and sell premium pdfs, pictures and videos through Premsi.</p>
                                        <p style={{ marginTop: 7, fontSize: 12, color: "black" }}>Download our App to easily create them.</p>
                                    </div>

                                    <button className='copyrefer' onClick={() => navigate("/app")} type='button' style={{ cursor: "pointer", border: "0", paddingRight: 15, paddingLeft: 15, borderRadius: 15, height: 30, backgroundColor: "#009FFD", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <h4 style={{ color: "white" }}>Download App🔥</h4>
                                    </button>
                                </div>

                                <div className='inrow'>
                                    <div style={{ flex: 1, alignItems: "center" }}>
                                        <h3>Your Referral code:</h3>
                                        <p style={{ marginTop: 7, fontSize: 12, color: "black" }}>Make money by referring new Premsi Users.</p>
                                        <p style={{ marginTop: 7, fontSize: 12, color: "black" }}>Currently: 5% for life on Premsi+ Subscription and purchase of premium content.</p>
                                    </div>

                                    <button className='copyrefer' onClick={() => (setcopiedreferral(true), navigator.clipboard.writeText(`https://premsi.com/?referral=${currentUser?.displayName}`))} type='button' style={{ cursor: "pointer", border: "0", paddingRight: 15, paddingLeft: 15, borderRadius: 15, height: 30, backgroundColor: "#009FFD", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        {copiedreferral
                                            ?
                                            <h4 style={{ color: "white" }}>Copied 🎉🔥</h4>
                                            :
                                            <h4 style={{ color: "white" }}>Copy my code 🎉</h4>
                                        }
                                    </button>
                                </div>

                                <div className='inrow'>
                                    <div style={{ flex: 1, alignItems: "center" }}>
                                        <h3>Connect Paypal:</h3>
                                        <p className='pptext'>This needs to be done to payout your earnings.</p>
                                        {currentUser?.paypalconnected ? <p style={{ marginTop: 7, fontSize: 12, color: "black" }}>{currentUser?.paypalemail}</p> : null}
                                        {currentUser?.paypalconnected ? <p style={{ fontSize: 12, color: "black" }}>To change please connect again your paypal.</p> : null}
                                    </div>
                                    {!currentUser?.paypalconnected
                                        ?
                                        <span id="paypal-button"></span>
                                        :
                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                            <div style={{ color: "darkgreen", marginBottom: 15, marginTop: 15, textAlign: "center", paddingLeft: 10, paddingRight: 10, fontWeight: "bold", border: "0", borderRadius: 15, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "lightgreen", height: 30 }}>
                                                <p style={{ fontSize: 12, color: "darkgreen" }}>Connected</p>
                                            </div>
                                            {currentUser?.managedaccount === false || currentUser?.managedaccount === undefined ? <span id="paypal-button"></span> : null}
                                        </div>
                                    }
                                </div>

                                <div className='profilescreen__plans'>
                                    <h3>Plans</h3>

                                    <PlansScreen />
                                </div>
                            </>
                        }

                        <button onClick={() => (auth.signOut(), navigate("/"))} className='profilescreen__signout'>Sign Out 😢</button>

                    </div>
                </div>
            </div>

            <Footer />

            <div hidden={showpaypalpopup} style={{
                zIndex: 100, position: "fixed", top: 0, left: 0,
                height: "100%", width: "100%", backgroundColor: "rgba(0,0,0,0.5)",
            }}>
                <div style={{ height: "100%", width: "100%", backgroundColor: "rgba(0,0,0,0.5)", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className='paypalconnect_popup'>
                        <h2>Connect Paypal ?</h2>
                        <h4>You are about to connect your paypal account to your Premsi account.</h4>
                        <h4>Make sure you have a verified paypal account with a verified e-mail.</h4>
                        <h4>This is needed in order to create payouts.</h4>
                        <div style={{ width: "100%", backgroundColor: "black", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            {!connectingerror
                                ?
                                (!connectingdone ?
                                    (!connectingpp ?
                                        <button onClick={connectpaypal} style={{ color: "darkgreen", paddingLeft: 10, paddingRight: 10, fontWeight: "bold", border: "0", marginLeft: 20, borderRadius: 15, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "lightgreen", height: 40 }}>
                                            <p style={{ fontSize: 14 }}>Connect Now</p>
                                        </button>
                                        :
                                        <ColorRing
                                            //https://mhnpd.github.io/react-loader-spinner/docs/components/color-ring
                                            visible={true}
                                            height="80"
                                            width="80"
                                            ariaLabel="blocks-loading"
                                            wrapperStyle={{}}
                                            wrapperClass="blocks-wrapper"
                                            colors={['#009FFD', 'white', '#009FFD', 'white', '#009FFD']}
                                        />

                                    )
                                    :
                                    <button onClick={() => (dispatch(getCurrentUserData()), setshowpaypalpopup(true))} style={{ color: "darkgreen", paddingLeft: 10, paddingRight: 10, fontWeight: "bold", border: "0", marginLeft: 20, borderRadius: 15, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "lightgreen", height: 40 }}>
                                        <p style={{ fontSize: 14 }}>Done! Go Back.</p>
                                    </button>
                                )
                                :
                                <button onClick={() => (setshowpaypalpopup(true))} style={{ color: "white", paddingLeft: 10, paddingRight: 10, fontWeight: "bold", border: "0", marginLeft: 20, borderRadius: 15, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "red", height: 40 }}>
                                    <p style={{ fontSize: 14 }}>There was an error! Retry.</p>
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileScreen
