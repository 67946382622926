import React, { Component, useEffect, useState } from "react";
import { Stripe, CardElement, injectStripe, Elements, ElementsConsumer, PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, updateDoc } from "firebase/firestore";
import { auth, db, functions } from "../../../firebase";
import { MdDelete } from "react-icons/md";
import { useSearchParams } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { stripePromise } from "../../../App";
import { Oval } from "react-loader-spinner";

// take from here stripe credit card payment for StripeBuyImmedaitaleyBtn.js and StripeBuyImmedaitaleyBtnnotloggedin.js

function PayElementStripePremsi({ amount }) {
  const [errorMessage, seterrorMessage] = useState(false);
  const [em, setem] = useState("");
  const [elementerror, setelementerror] = useState(false);
  const [loading, setloading] = useState(false);
  const [successpayment, setsuccesspayment] = useState(false);

  const stripe = useStripe()
  const elements = useElements()

  // for delay
  const delay = ms => new Promise(res => setTimeout(res, ms));

  useEffect(() => {
    try {
      let e = elements.getElement(PaymentElement)
      e.on('loaderror', function (event) {
        // Handle loaderror event
        console.log("loaserr", event)
      });
    } catch (error) {
      console.log(error)
    }
  }, [elements]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    seterrorMessage(false)
    setloading(true)
    setsuccesspayment(false)
    setelementerror(false)
    console.log("paymentelement for not logged in", elements)

    if (elements == null || stripe == null) {
      return;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError?.message) {
      // Show error to your customer
      console.log(submitError.message);
      setelementerror(true)
      setloading(false)
      return;
    }
    console.log("all good")

    const stripeclientsecret = httpsCallable(functions, 'getstripeclientsecretloggedin');
    stripeclientsecret({ currency: "usd", amount: amount, user: auth.currentUser.uid })
      .then(async (res) => {
        // Read result of the Cloud Function.
        console.log("resulttt checkorderbackend", res.data.client_secret)

        //this below to cinform payment
        const { paymentIntent, error } = await stripe.confirmPayment({
          //`Elements` instance that was used to create the Payment Element
          elements,
          clientSecret: res.data.client_secret,
          confirmParams: {
            return_url: `${window.location.origin}`, //hier richtige url zum checken
          },
          redirect: "if_required"
        });

        if (error && error.code === "card_declined") {
          console.log("There was an error saving the card", error)
          seterrorMessage(true)
          setem("Your card was declined. Please retry")
          setloading(false)
          return
        } else if (error && error.code === "expired_card") {
          console.log("There was an error saving the card", error)
          seterrorMessage(true)
          setem("Your card expired. Please retry with another one.")
          setloading(false)
          return
        } else if (error && error.code === "incorrect_cvc") {
          console.log("There was an error saving the card", error)
          seterrorMessage(true)
          setem("Your CVC code is wrong.")
          setloading(false)
          return
        } else if (error && error.code === "incorrect_number") {
          console.log("There was an error saving the card", error)
          seterrorMessage(true)
          setem("Your card number is incorrect. Please retry")
          setloading(false)
          return
        } else if (error) {
          console.log("There was an error saving the card", error)
          seterrorMessage(true)
          setem("Your card transaction didn´t work. Please retry")
          setloading(false)
          return
        } else {
          console.log("this is paymentIntent", paymentIntent)
          // send paymentIntent to backend

          const stripeclientsecret = httpsCallable(functions, 'updatepamyentintentpayedloggedin');
          stripeclientsecret({ paymentintent: JSON.stringify(paymentIntent) })
            .then(async (res) => {

              console.log("This is result of updatepamyentintentpayedloggedin", res)
              if (res.data === "success") {
                setsuccesspayment(true)
                setloading(false)
              } else {
                seterrorMessage(true)
                setem("Your card transaction didn´t work. Please retry")
                setloading(false)
              }
            }).catch((e) => {
              console.log("There was an error saving the card", e)
              seterrorMessage(true)
              setem("Your card transaction didn´t work. Please retry")
              setloading(false)
              return
            })
        }
      })
      .catch((error) => {
        const code = error.code;
        const message = error.message;
        console.log("error", code, message)
      });
  };

  return (
    <form style={{ alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column" }}>
      {successpayment
        ?
        <div style={{ color: "green", fontSize: 14, marginTop: 10 }}>Your Payment was succcessful. Your account got funded with {amount}$.</div>
        :
        <PaymentElement />}

      {loading
        ?
        <Oval
          height={40}
          width={40}
          color="#009FFD"
          wrapperStyle={{ flex: "display", alignItems: "center", justifyContent: "center" }}
          wrapperClass=""
          visible={true}
          ariaLabel='oval-loading'
          secondaryColor="white"
          strokeWidth={5}
          strokeWidthSecondary={5}
        />
        :
        (successpayment
          ?
          <button onClick={(e) => (e.preventDefault(), setsuccesspayment(false))} style={{ backgroundColor: "#009ffd", color: "white", border: "none", marginTop: 10, height: 30, paddingLeft: 10, paddingRight: 10, borderRadius: 20, fontSize: 15 }} disabled={!stripe}>
            New Payment
          </button>
          :
          <button onClick={(e) => handleSubmit(e)} style={{ backgroundColor: "#009ffd", color: "white", border: "none", marginTop: 10, height: 30, width: 100, borderRadius: 20, fontSize: 15 }} disabled={!stripe}>
            Pay
          </button>)
      }
      {errorMessage && <div style={{ color: "red", fontSize: 14, marginTop: 10 }}>{em}</div>}
      {elementerror && <div style={{ color: "red", fontSize: 14, marginTop: 10, textAlign: "center" }}>There has been an error. Please retry your payment information.</div>}
    </form>
  );
}

export default PayElementStripePremsi;