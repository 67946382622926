import React, { Component, useEffect, useState } from "react";
import { Stripe, CardElement, injectStripe, Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, updateDoc } from "firebase/firestore";
import { auth, db, functions } from "../../../firebase";
import { useSearchParams } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { Helmet } from "react-helmet";
import Nav from "../../../components/Nav/Nav";
import VerifyEmailComponent from "../../../components/rememberverifyemail";
import Footer from "../../../components/Footer";
import { Oval } from "react-loader-spinner";

// purpose of this screen is that when verifiying payment on phone user will be redirected to this page and with params
// useeffect will get info and retrievepaymentintent and set the new status of the payment to the backend 
// so that backend function confirms the paymetn

function VerifyPaymentScreen({ stripe, elements }) {
  const [errorMessage, seterrorMessage] = useState(false);
  const [loading, setloading] = useState(true);
  const [workedverifying, setworkedverifying] = useState(false);
  const [noparams, setnoparams] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  // for delay
  const delay = ms => new Promise(res => setTimeout(res, ms));

  useEffect(() => {

    const f = async () => {
      let piclientsecret = searchParams.get("payment_intent_client_secret")
      let docid = searchParams.get("docid")
      let uid = searchParams.get("uid")
      console.log("useefect for clientsecret", piclientsecret, docid)
      setloading(true)
      seterrorMessage(false)

      if (piclientsecret === null || piclientsecret === undefined || piclientsecret === "" || uid === undefined || uid === null || uid === "") {
        console.log("nothing", piclientsecret, docid, uid)
        setnoparams(true)
        setloading(false)
        return
      } else {
        console.log("exists so", docid, piclientsecret, uid)
        const { paymentIntent, error } = await stripe.retrievePaymentIntent(piclientsecret);
        console.log("paymentintent", paymentIntent, docid)

        if (paymentIntent && paymentIntent.status === 'requires_confirmation') {
          console.log("paymentintent", paymentIntent)
          //here add paymentintent to object
          console.log("thos is payment in useeffect in vrifypaymentforphone", paymentIntent)

          // here call backend that verifies payment
          const ver = httpsCallable(functions, 'stripeverifypaymentforphone');
          ver({ docid: docid, uid: uid, paymentintent: JSON.stringify(paymentIntent) })
            .then((result) => {
              console.log("res", result)
              if (result.data === "success") {
                setworkedverifying(true)
                setloading(false)
              } else {
                setloading(false)
                seterrorMessage(true)
              }
            })
            .catch((error) => {
              console.log(error.code)
              setloading(false)
            });

          // Handle successful payment here
        } else if (paymentIntent && paymentIntent.status === 'succeeded') {
          // Handle unsuccessful, processing, or canceled payments and API errors here
          console.log("paymentintent", paymentIntent)
          setworkedverifying(true)
          setloading(false)
        } else {
          console.log(error.code)
          setloading(false)
          seterrorMessage(true)
        }
      }
    }
    if (stripe === null) {
      setloading(false)
      return
    } else {
      f()
    }
  }, [stripe]);

  return (
    <div style={{ width: "100%", height: "100%", backgroundColor: "white" }}>
      <Helmet>
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Premsi | User - Sell digital content in minutes❤️" />
        <meta property="og:description" content="Premsi | Sell and market digital content in seconds. Download the Premsi App for more features. Premsi lets you easily sell and market your Videos/Pics/PDFs/Groups and soon courses. All for free and in 1 minute." />
        <meta property="og:url" content="https://premsi.com/user" />
        <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/premsicom-accb2.appspot.com/o/P1.png?alt=media&token=85d8afdb-d43d-4c28-9c1b-862ea8659fad" />
        <meta property="og:site_name" content="Premsi - sell digital content" />
        <link rel="canonical" href="https://premsi.com/user" />

        <title>Verify your Premsi Payment</title>
        <meta name="description" content={"Verify your payment"} />
      </Helmet>
      <Nav />
      {auth.currentUser === null || auth.currentUser === undefined || auth.currentUser.emailVerified === true ? null : <VerifyEmailComponent />}

      <div style={{ minHeight: "75%", display: "flex", width: "100%", alignItems: "center", flexDirection: "column", paddingTop: 20, }}>

        {loading
          ?
          <div>
            <Oval
              height={40}
              width={40}
              color="#009FFD"
              wrapperStyle={{ flex: "display", alignItems: "center", justifyContent: "center" }}
              wrapperClass=""
              visible={true}
              ariaLabel='oval-loading'
              secondaryColor="white"
              strokeWidth={5}
              strokeWidthSecondary={5}
            />
            <p style={{ marginTop: 10 }}>Verifying your payment right now. This might take a moment.</p>
          </div>
          :
          (workedverifying
            ?
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <p style={{ marginBottom: 10, paddingTop: 10, paddingBottom: 10, paddingLeft: 20, paddingRight: 20, borderRadius: 10, backgroundColor: "green", color: "white", textAlign: "center" }}>Verified payment ✅</p>
              <p>You can close the screen. The funds will be added soon to your balance!</p>
            </div>
            :
            (noparams
              ?
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <p style={{ marginBottom: 10, paddingTop: 10, paddingBottom: 10, paddingLeft: 20, paddingRight: 20, borderRadius: 10, backgroundColor: "red", color: "white", textAlign: "center" }}>No Parameters. Wrong Link.</p>
                <p>Please close the screen.</p>
              </div>
              :
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <p style={{ marginBottom: 10, paddingTop: 10, paddingBottom: 10, paddingLeft: 20, paddingRight: 20, borderRadius: 10, backgroundColor: "red", color: "white", textAlign: "center" }}>Didn't work verifying!</p>
                <p>Please close the screen.</p>
              </div>
            ))
        }

        {errorMessage ? <p style={{ color: "red", marginTop: 15, fontSize: 14 }}>There has been an error verifying your payment. Please retry. You didn´t get charged.</p> : null}
      </div>
      <Footer />
    </div>
  );

}

export default VerifyPaymentScreen;