import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './PremiumMessage.css';
import Nav from '../../components/Nav/Nav'
import { useSelector } from 'react-redux'
import { getCurrentUser } from '../../features/currentUser'
import { Oval, MagnifyingGlass } from 'react-loader-spinner'
import { boughtPremiumMessage, getCurrentUserFinancials, getGroupById, getPostWithId, getPremiumMessageById, getUserInfoWithId, unsubFinancials } from '../../services/f';
import { FaComment, FaShare } from 'react-icons/fa'
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';
import { PiUserCircleLight } from 'react-icons/pi'
import Footer from '../../components/Footer';
import { auth, functions } from '../../firebase';
import VerifyEmailComponent from '../../components/rememberverifyemail';
import { Helmet } from 'react-helmet';
import { TwitterShareButton } from 'react-twitter-embed';
import { HiOutlineLockClosed } from "react-icons/hi";
import PaypalBuyImmediatelyBtn from '../../components/PaypalBuyImmediatelyBtn/PayPalBuyImmediatelyBtn';
import PaypalBuyImmediatelyBtnNotLoggedIn from '../../components/PaypalBuyImmediatelyBtnNotLoggedIn/PaypalBuyImmediatelyBtnNotLoggedIn';
import { httpsCallable } from 'firebase/functions';
import { useResizeObserver } from '@wojtekmaj/react-hooks';
import { pdfjs, Document, Page } from 'react-pdf';
import VideoPlayerBoughtPM from '../../components/VideoPlayer/VideoPlayerBoughtPM';
import StripeBuyImmediatelyBtnNotLoggedIn from '../../components/StripeBuyImmediatelyBtnNotLoggedIn/StripeBuyImmediatelyBtnNotLoggedIn';
import StripeBuyImmediatelyBtn from '../../components/StripeBuyImmediatelyBtn/StripeBuyImmediatelyBtn';
import { stripePromise } from '../../App';
import { Elements } from '@stripe/react-stripe-js';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

const resizeObserverOptions = {};

const maxWidth = 800;

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};

//see which one google indees and which notif there are dofferences
//check faq pmitem app profile
function PremiumMessage() {
  // http://localhost:3000/pm?id=8XNWZWyYVJ1KAJhpw2m4
  // http://localhost:3000/pm?id=8XNWZWyYVJ1KAJhpw2m4

  // https://premsi.com/boughtpm?email=vanaprogr@gmail.com&id=8XNWZWyYVJ1KAJhpw2m4
  const [searchParams, setSearchParams] = useSearchParams();
  let currentUser = useSelector(getCurrentUser).currentUser?.currentUser// ALSO WENNS n user gibt dann wird anderes screen angezeigt
  const navigate = useNavigate()

  const [pmitem, setpmitem] = useState(null);
  const [financials, setfinancials] = useState();

  const [copiedlink, setcopiedlink] = useState(false);
  const [loading, setloading] = useState(false);
  const [boughtpmerror, setboughtpmerror] = useState(false);
  const [boughtsuccess, setboughtsuccess] = useState(false);

  const [email, setemail] = useState("");
  const [isemail, setisemail] = useState(true);

  const [pw, setpw] = useState("");

  const [buypm, setbuypm] = useState(false);
  const [showpp, setshowpp] = useState(false);

  // for pdf
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [containerRef, setContainerRef] = useState(null);
  const [containerWidth, setContainerWidth] = useState();

  // to show all premiumposts in an array when pm free
  const [retrievedpm, setretrievedpm] = useState(null);
  const [loadingerror, setsetloadingerror] = useState(false);
  const [isnotfree, setisnotfree] = useState(false);
  const [loadingpm, setloadingpm] = useState(false);
  const [arrayofmedia, setarrayofmedia] = useState([]);

  // for payment method choose
  const [buymethod, setbuymethod] = useState(0);

  const onResize = useCallback((entries) => {
    const [entry] = entries;
    //console.log("resized", entry.contentRect.width)

    if (entry) {
      setContainerWidth(entry.contentRect.width);
    }
  }, []);

  useResizeObserver(containerRef, resizeObserverOptions, onResize);

  let titledescription
  let titletext

  function onDocumentLoadSuccess({ numPages }) {
    //console.log("this isnumPages", numPages)
    setNumPages(numPages);
  }

  useEffect(() => {
    let pmid = searchParams.get("id")
    if (pmid === null || pmid === undefined || pmid === "") {
      setpmitem("pmnotexists")
    } else {
      getPremiumMessageById(pmid).then((res) => {
        ////console.log("getPostWithId result", res)
        setpmitem(res)
        titledescription = "Premium Message description:  " + res[1]?.description + "Sell and market digital content in seconds . Premsi lets you easily sell and market your Videos/Pics/PDFs/Groups and soon courses. All for free and in 1 minute."
        titletext = "Premsi - Premium Message: " + res[1]?.grouptitle
        if (res === "pmnotexists") {
          return
        }
      })
    }

  }, []);

  useEffect(() => {
    if (auth.currentUser === null || auth.currentUser === undefined) {
      return
    } else {
      if (buypm === true) {
        ////console.log("get financials")
        getCurrentUserFinancials(setfinancials)
        return () => {
          ////console.log("screen gone")
          unsubFinancials()
        }
      } else {
        return
      }
    }
  }, [buypm]);

  const buypmmessage = () => {
    //console.log("bus message")
    setloading(true)
    boughtPremiumMessage(auth.currentUser.uid, pmitem[1], pmitem[0]).then((message) => {
      //console.log("boughtPremiumMessage", message)
      if (message === "success") {
        //console.log("bought")
        setboughtsuccess(true)
        setloading(false)
      } else {
        setboughtpmerror(true)
        setloading(false)
      }
    })
  }

  useEffect(() => {
    console.log("restrievedpm useeffect", retrievedpm)
    let i = 0
    let farray = []

    if (retrievedpm === null) {
      return
    } else {
      while (i < retrievedpm?.data.amountfiles) {
        farray.push(retrievedpm?.data.media[i])
        i++
      }
      setarrayofmedia(farray)
    }
  }, [retrievedpm]);

  const unlock = () => {
    console.log("unlock it", pmitem, auth.currentUser)
    setloadingpm(true)
    setisnotfree(false)

    const checkpmbackend = httpsCallable(functions, 'retrieveandcheckfreePM');
    checkpmbackend({ pmid: pmitem[0], user: (auth.currentUser === null ? null : auth.currentUser.uid) })
      .then((result) => {
        // Read result of the Cloud Function.
        //console.log("resulttt checkpmbackend", result.data)
        if (result.data === "isnotfree") {
          setisnotfree(true)
          setloadingpm(false)
        } else {
          console.log("resulttt checkpmbackend", result)
          setretrievedpm(result.data)
          setloadingpm(false)
        }
      })
      .catch((error) => {
        // Getting the Error details.
        const code = error.code;
        const message = error.message;
        const details = error.details;
        //console.log("errorcloudfunction", error)
        //if response is error let user know with alert or so
        setsetloadingerror("there is an error on baclend")
        setloadingpm(false)
      });
  }

  //take all functions that open in PremiumMessageBought.js

  const freenotloggedin = () => {
    console.log("free but not logged in, just show it here get from backend and increment bought by 1 for creator")
    // so retreive from backend send it to frontend and once its here set it into a usestate and show it then here instead of the lock sign
    unlock()
  }

  const freeloggedin = () => {
    console.log("free and logged in, add to catalogue bought and show it here and increment bought by 1 for creator")
    // so retreive from backend send it to frontend and once its here set it into a usestate and show it then here instead of the lock sign
    // and then in backend add the premiummessage to user so that he sees in app also
    unlock()
  }

  useEffect(() => {
    //console.log("email", email)
    // don't remember from where i copied this code, but this works.
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(email)) {
      // this is a valid email address
      setisemail(true)
    }
    else {
      // invalid email, maybe show an error to the user.
      setisemail(false)
    }
  }, [email]);

  return (
    <div className='postscreen'>
      <Helmet>

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Premsi | Premium Message - Easily sell your digital content❤️" />
        <meta property="og:description" content="Premsi | Sell and market digital content in seconds . Premsi lets you easily sell and market your Videos/Pics/PDFs/Groups and soon courses. All for free and in 1 minute." />
        <meta property="og:url" content="https://premsi.com/pm" />
        <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/premsicom-accb2.appspot.com/o/P1.png?alt=media&token=85d8afdb-d43d-4c28-9c1b-862ea8659fad" />
        <meta property="og:site_name" content="Premsi - sell digital content" />
        <link rel="canonical" href="https://premsi.com/pm" />

        <title>{titletext}</title>
        <meta name="description" content={String(titledescription)} />

        <meta name="twitter:title" content="Premsi" />
        <meta name="twitter:description" content={`Premsi.com Premium Message`} />
        <meta name="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/premsicom-accb2.appspot.com/o/P1.png?alt=media&token=85d8afdb-d43d-4c28-9c1b-862ea8659fad" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Nav />
      {auth.currentUser === null || auth.currentUser === undefined || auth.currentUser.emailVerified === true ? null : <VerifyEmailComponent />}

      <div className='postscreen__body'>

        {pmitem === null ?
          <div>
            <Oval
              height={40}
              width={40}
              color="#009FFD"
              wrapperStyle={{ marginTop: 20, flex: "display", alignItems: "center", justifyContent: "center" }}
              wrapperClass=""
              visible={true}
              ariaLabel='oval-loading'
              secondaryColor="white"
              strokeWidth={5}
              strokeWidthSecondary={5}
            />
            <p style={{ marginBottom: 3, textAlign: "center" }}>Loading premium message...</p>
            <p style={{ textAlign: "center", fontSize: 12 }}>If this takes too long please refresh this window.</p>
          </div>
          :
          (
            pmitem === "pmnotexists"
              ?
              <div>
                <MagnifyingGlass
                  height={40}
                  width={40}
                  color="#009FFD"
                  wrapperStyle={{ marginTop: 20, width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}
                  wrapperClass=""
                  visible={true}
                  ariaLabel='oval-loading'
                  secondaryColor="white"
                  strokeWidth={5}
                  strokeWidthSecondary={5}
                />
                <h1 style={{ marginBottom: 3, textAlign: "center", color: "black", fontSize: 17 }}>No Premium Message...</h1>
                <p style={{ textAlign: "center", fontSize: 12, marginTop: 20 }}>It seems that you have a wrong link and this Premium Message doesn't exist</p>
                <p style={{ textAlign: "center", fontSize: 12 }}>Please recheck your link.</p>
                <div style={{ display: "flex", alignItems: "center", marginTop: 20, justifyContent: "center", width: "100%", marginBottom: 5 }}>
                  <button onClick={() => navigate("/")} style={{ fontSize: 14, fontWeight: "bold", paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5, borderRadius: 10, backgroundColor: "#009FFD", color: "white", borderWidth: 0 }}>Explore others</button>
                </div>
              </div>
              :
              <>
                <div className='postscreen__infopm'>
                  {/*<div className='profilepicdiv'>
                    <img style={{ pointerEvents: "none" }} className='avatarpostscreen' src={pmitem[1]?.media} alt="group pic" />
          </div>*/}

                  <div style={{ width: "100%", display: "flex", marginBottom: 10, alignItems: "center", justifyContent: "center" }}>
                    <h1 style={{ color: "black", fontSize: 17 }}>Premsi Premium Message:</h1>
                  </div>

                  <div className='postscreen__details'>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <button onClick={() => navigate("/user?id=" + pmitem[1]?.creator)} className='useremail'> {pmitem[1].type === "image" ? "Image" : (pmitem[1].type === "video" ? "Video" : "PDF")} by: {pmitem[1]?.creatordisplayname}</button>
                    </div>
                  </div>
                </div>

                {loadingpm
                  ?
                  <div className='postidvouter'>
                    <div className='postdivpm'>
                      <Oval
                        height={40}
                        width={40}
                        color="white"
                        wrapperStyle={{ flex: "display", alignItems: "center", justifyContent: "center" }}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="white"
                        strokeWidth={5}
                        strokeWidthSecondary={5}
                      />
                    </div>
                  </div>
                  : (isnotfree
                    ?
                    <div className='postidvouter'>
                      <div className='postdivpm'>
                        <h4>This Premium Message is not free</h4>
                      </div>
                    </div>
                    :
                    (retrievedpm === null || retrievedpm === undefined
                      ?
                      <div className='postidvouter'>
                        <div className='postdivpm'>
                          <HiOutlineLockClosed size={23} color='#009FDD' alt='private Premsi premium message' />
                        </div>
                      </div>
                      :
                      (retrievedpm?.data.type === "image" ?

                        <div className='uploadfileouterdiv' >
                          {arrayofmedia.map((mappedimage, i) =>
                            <div style={{ height: 200, aspectRatio: 12 / 16, borderRadius: 20, backgroundColor: "black", display: "flex", alignItems: "center", justifyContent: "center" }}>
                              <img
                                loading="lazy"
                                key={mappedimage[1]}
                                src={mappedimage[1]}
                                style={{ pointerEvents: "none", height: "100%", aspectRatio: 12 / 16, borderRadius: 20, objectFit: "contain" }}
                                onError={(i) => (i.target.src = require('../../images/pblue-min.webp'))}
                                onClick={() => null}
                                alt={"Premsi Premium Message" + retrievedpm?.data.description}
                              />
                            </div>
                          )}
                        </div>
                        :
                        (retrievedpm?.data.type === "video"
                          ?
                          //here slider
                          <div className='uploadfileouterdiv' >
                            {arrayofmedia.map((mappedvideo, i) =>
                              <div style={{ height: 200, aspectRatio: 12 / 16, overflow: "hidden", borderRadius: 20, backgroundColor: "black", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <VideoPlayerBoughtPM style={{ pointerEvents: "none", height: "100%", backgroundColor: "white", aspectRatio: 12 / 16, borderRadius: 20 }} src={mappedvideo[0]} />
                              </div>
                            )}
                          </div>

                          :
                          <div style={{ pointerEvents: "none" }} className="Example__container" ref={setContainerRef}>
                            <div style={{ backgroundColor: "#009FFD", paddingLeft: 20, marginBottom: 10, display: "flex", alignItems: "center", justifyContent: "center", paddingRight: 20, paddingTop: 10, paddingBottom: 10, borderRadius: 15 }}>
                              <p style={{ color: "white", fontSize: 14 }}>Total {numPages} pages</p>
                            </div>

                            <div className="Example__container__document">
                              <Document options={options} style={{ width: "80%", height: "80%" }} onLoadSuccess={onDocumentLoadSuccess} file={retrievedpm?.data.media}>
                                {
                                  Array.apply(null, Array(numPages))
                                    .map((x, i) => i + 1)
                                    .map((page) => {
                                      return (
                                        <Page
                                          pageNumber={page}
                                          renderTextLayer={false}
                                          renderAnnotationLayer={false}
                                          width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
                                          style={{ marginBottom: 30 }}
                                        />
                                      )
                                    })
                                }
                              </Document>
                            </div>
                          </div>

                          //retrievedpm?.data.pmi.media[0]
                          //https://firebasestorage.googleapis.com/v0/b/premsicom-accb2.appspot.com/o/premiummessagepdf%2FqVe5p15eD8a4U3Tw3OMdPSsBSdh2%2Fade03a63-1020-431f-917b-410aba42826c?alt=media&token=d518e82c-0947-473e-8943-fdb65f22eb90
                        )
                      )
                    )
                  )
                }

                {pmitem[1]?.amountfiles === null || pmitem[1]?.amountfiles === undefined
                  ?
                  null
                  :
                  <div className='amountfilesouter'>
                    <div className='amountfilesinner'>
                      <h5 style={{ textAlign: "center" }}>{pmitem[1]?.amountfiles} 🏞️</h5>
                    </div>
                  </div>}

                <div className='groupdescription'>
                  <div className='groupdescriptioninner'>
                    <h5 style={{ textAlign: "center" }}>{pmitem[1]?.description}</h5>
                  </div>
                </div>

                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <div className='postinfopm'>
                    <div className='postinnerinfo'>

                      <button className='sharebtn' onClick={() => (navigator.clipboard.writeText("www.premsi.com/pm?id=" + pmitem[0]), setcopiedlink(true))}>
                        {
                          copiedlink
                            ?
                            <p>Copied ✅</p>
                            :
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                              <FaShare size={22} color='black' alt='share post' />
                              <p style={{ color: "black", textAlign: "center" }}>Share</p>
                            </div>
                        }
                      </button>
                      <TwitterShareButton
                        onLoad={function noRefCheck() { }}
                        options={{
                          buttonHashtag: undefined,
                          screenName: undefined,
                          size: 'large',
                          text: "See this new Premsi Premium Message 🔥🧿",
                          //via: 'saurabhnemade'
                        }}
                        placeholder={<div style={{ backgroundColor: 'transparent', color: 'white', margin: 10, padding: 10, textAlign:"center",fontSize:11 }}>loading x</div>}
                        url={`https://www.premsi.com/pm?id=${pmitem[0]}`}
                      />
                    </div>
                  </div>
                </div>

                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                  {loading
                    ?
                    <div className='buypmbtn'>
                      <Oval
                        height={40}
                        width={40}
                        color="white"
                        wrapperStyle={{ flex: "display", alignItems: "center", justifyContent: "center" }}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="white"
                        strokeWidth={5}
                        strokeWidthSecondary={5}
                      />
                    </div>
                    : (buypm
                      ?
                      (auth.currentUser === null || auth.currentUser === undefined ?
                        <div className='buypmbtn'>
                          <p style={{ color: "white", marginBottom: 10, fontSize: 14, fontWeight: "bold", borderRadius: 10, paddingTop: 10, paddingBottom: 10, paddingRight: 20, paddingLeft: 20, textAlign: "center", backgroundColor: "rgba(255,255,255,0.4)" }}>{pmitem[1].price}$</p>
                          <p style={{ width: "80%", color: "white", marginBottom: 15 }}>We will send the premium message to this email and with the password you can access it. Don't share it, otherwise other people can access your account.</p>
                          <input disabled={showpp} style={{ border: "none", color: "white", fontSize: 15, borderRadius: 20, height: 40, textAlign: "center", width: 250, backgroundColor: "rgba(255,255,255,0.4)" }} type="email" placeholder='Your E-Mail' onChange={(e) => setemail(e.target.value)} />
                          <input disabled={showpp} style={{ border: "none", marginTop: 15, marginBottom: 10, color: "white", fontSize: 15, borderRadius: 20, height: 40, textAlign: "center", width: 250, backgroundColor: "rgba(255,255,255,0.4)" }} type="text" placeholder='Set a password' onChange={(e) => setpw(e.target.value)} />

                          {isemail ? null : <p className='noemail'>Please insert a real e-mail and password for purchasing</p>}

                          {isemail && pw !== "" && showpp === false ?
                            <div onClick={() => setshowpp(true)} className='buypmbtnendpp'>
                              <p style={{ color: "white", fontSize: 14, fontWeight: "bold" }}>Buy</p>
                            </div>
                            : <div onClick={() => setshowpp(false)} className='buypmbtnendpp'>
                              <p style={{ color: "white", fontSize: 14, fontWeight: "bold" }}>Back</p>
                            </div>
                          }

                          {showpp ?
                            <>
                              <div onClick={() => setbuymethod(1)} className='buypmbtnend'>
                                <p style={{ color: "white", fontSize: 14, fontWeight: "bold" }}>Buy with Paypal 💰</p>
                              </div>
                              <div onClick={() => setbuymethod(2)} className='buypmbtnend' style={{ marginBottom: 10 }}>
                                <p style={{ color: "white", fontSize: 14, fontWeight: "bold" }}>Buy with Credit Card 💰</p>
                              </div>
                            </>
                            : null}

                          {buymethod === 0 ? null : <p style={{ fontSize: 22, marginBottom: 10, color: "white" }}>⬇</p>}
                          {buymethod === 0 ? null :
                            (buymethod === 1 ?
                              <PaypalBuyImmediatelyBtnNotLoggedIn pmitem={pmitem} price={Number(pmitem[1].price)} email={email} pw={pw} />
                              :

                              <Elements stripe={stripePromise} options={{ mode: "payment", amount: 122, currency: "usd" }}>
                                <StripeBuyImmediatelyBtnNotLoggedIn pmitem={pmitem} price={Number(pmitem[1].price)} email={email} pw={pw} />
                              </Elements>
                            )
                          }

                        </div>
                        :
                        <div className='buypmbtn'>
                          {boughtsuccess ?
                            <>
                              <p style={{ color: "white", marginBottom: 7, fontSize: 14 }}>You successfully bought this premium message.</p>
                              <p style={{ color: "white", marginBottom: 7, fontSize: 14 }}>Download the Premsi <a href="app" style={{ color: "white" }}>App</a> to view it in your profile 🎉</p>
                            </>
                            :
                            <>
                              <p style={{ color: "white", marginBottom: 7, fontSize: 14 }}>Your balance: {financials?.balance}$</p>
                              <p style={{ color: "white", marginBottom: 7, fontSize: 12, textDecoration: "underline", cursor: "pointer" }} onClick={() => navigate("/profile")}>Add funds</p>
                              {Number(financials?.balance) >= Number(pmitem[1].price)
                                ?
                                <div onClick={() => buypmmessage()} className='buypmbtnend'>
                                  <p style={{ color: "white", fontSize: 14, fontWeight: "bold" }}>Buy for {pmitem[1].price}$ with funds 💰</p>
                                </div>
                                :
                                <div onClick={() => navigate("/profile")} className='buypmbtnendnotenoguhbalance'>
                                  <p style={{ color: "white", fontSize: 12, fontWeight: "bold" }}>Add funds to buy</p>
                                </div>
                              }

                              <p style={{ color: "white", marginTop: 12, marginBottom: 10, fontSize: 12 }}>Or buy immediately: </p>
                              <div onClick={() => setbuymethod(1)} className='buypmbtnend'>
                                <p style={{ color: "white", fontSize: 14, fontWeight: "bold" }}>Buy with Paypal 💰</p>
                              </div>
                              <div onClick={() => setbuymethod(2)} className='buypmbtnend' style={{ marginBottom: 10 }}>
                                <p style={{ color: "white", fontSize: 14, fontWeight: "bold" }}>Buy with Credit Card 💰</p>
                              </div>
 
                              {buymethod === 0 ? null : <p style={{ fontSize: 22, marginBottom: 10, color: "white" }}>⬇</p>}
                              {buymethod === 0 ? null :
                                (buymethod === 1 ?
                                  <PaypalBuyImmediatelyBtn pmitem={pmitem} price={Number(pmitem[1].price)} />
                                  :
                                  <Elements stripe={stripePromise} options={{ mode: "payment", amount: 122, currency: "usd" }}>
                                    <StripeBuyImmediatelyBtn pmitem={pmitem} price={Number(pmitem[1].price)} />
                                  </Elements>
                                )
                              }

                            </>
                          }
                        </div>
                      )
                      :
                      <div onClick={() => (Number(pmitem[1].price) === 0 && (auth.currentUser === null || auth.currentUser === undefined) ? freenotloggedin() : (Number(pmitem[1].price) === 0 ? freeloggedin() : setbuypm(true)))} className='buypmbtnapp'>
                        {Number(pmitem[1].price) === 0
                          ?
                          <p style={{ color: "white", fontSize: 14, fontWeight: "bold" }}>Get it for free 😇</p>
                          :
                          <p style={{ color: "white", fontSize: 14, fontWeight: "bold" }}>Buy for {pmitem[1].price}$ 💰</p>}
                      </div>
                    )
                  }
                </div>

                {loadingerror ? <p style={{ textAlign: "center", color: "red", fontSize: 13, marginTop: 5 }}>There was an error retrieving this Premium Message from our servers.</p> : null}

                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <h2 className='belowuserinfo'>You can also buy this Premium Message in our <a href="app" style={{ color: "white" }}>App</a>.</h2>
                </div>
              </>
          )
        }
      </div>

      <Footer />

    </div >
  )
}

export default PremiumMessage


