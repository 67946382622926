import React, { Component, useEffect, useState } from "react";
import { Stripe, CardElement, injectStripe, Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, limit, orderBy, query, startAfter, updateDoc } from "firebase/firestore";
import { auth, db, functions } from "../../../firebase";
import { MdDelete } from "react-icons/md";
import { useSearchParams } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
function CardRow({ card, index, setchosen, chosen, showCards }) {
  //console.log("in CardRow", card)

  const deleteCard = () => {
    const Ref = doc(db, 'stripe_customers', auth.currentUser.uid, "payment_methods", card.firebaseid);
    deleteDoc(Ref).then(() => {
      showCards()
    })
  }

  return (
    <div style={{ borderRadius: 15, height: 40, color: "black", width: "90%", margintop: 10, marginBottom: 10, display: "flex", alignItems: "center", justifyContent: "space-around", flexDirection: "row", borderStyle: "solid", borderColor: "#009ffd" }}>
      <p>**** **** **** {card.card.last4}</p>
      <p>Expire: {card.card.exp_month}/{card.card.exp_year}</p>
      {/*<div onClick={() => setchosen(index)} style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingTop: 4, paddingLeft: 10, paddingRight: 10, paddingBottom: 4, borderRadius: 10, backgroundColor: (chosen === index ? "#009ffd" : "lightblue") }}>
        <p style={{ fontSize: 13 }}>Choose</p>
      </div>*/}
      <MdDelete size={20} onClick={() => deleteCard()} />
    </div>
  )
}

function TransactionRow({ transaction }) {
  //console.log("in TransactionRow",transaction, transaction.amount, transaction.status, transaction.payment_methodfirst.card.brand, transaction.payment_methodfirst.card.last4,)
  const [date, setdate] = useState("");
  useEffect(() => {
    let offset = new Date().getHours() - new Date().getUTCHours()//this after seconds: + offset * 60 * 60 * 1000
    let year = new Date(transaction.created * 1000 + offset * 60 * 60 * 1000).toISOString().slice(2, 4)
    let month = new Date(transaction.created * 1000 + offset * 60 * 60 * 1000).toISOString().slice(5, 7)
    let day = new Date(transaction.created * 1000 + offset * 60 * 60 * 1000).toISOString().slice(8, 10)
    let time = new Date(transaction.created * 1000 + offset * 60 * 60 * 1000).toISOString().slice(11, 16)
    let endvalue = month + "." + day + "." + year + " " + time
    setdate(endvalue)
  }, []);

  // Format amount for diplay in the UI
  function formatAmount(amount, currency) {
    amount = zeroDecimalCurrency(amount, currency)
      ? amount
      : (amount / 100).toFixed(2);
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
    }).format(amount);
  }

  // Check if we have a zero decimal currency
  // https://stripe.com/docs/currencies#zero-decimal
  function zeroDecimalCurrency(amount, currency) {
    let numberFormat = new Intl.NumberFormat(['en-US'], {
      style: 'currency',
      currency: currency,
      currencyDisplay: 'symbol',
    });
    //console.log("numberformat", numberFormat)
    const parts = numberFormat.formatToParts(amount);
    let zeroDecimalCurrency = true;
    for (let part of parts) {
      if (part.type === 'decimal') {
        zeroDecimalCurrency = false;
      }
    }
    return zeroDecimalCurrency;
  }

  return (
    <div style={{ margin: "auto", borderRadius: 15, height: 40, color: "black", width: "90%", margintop: 10, marginBottom: 10, display: "flex", alignItems: "center", justifyContent: "space-around", flexDirection: "row", borderStyle: "solid", borderColor: "#009ffd" }}>
      <p style={{ fontSize: 14 }}>{formatAmount(transaction.amount, "usd")}</p>
      <p style={{ fontSize: 14 }}>*{transaction.payment_methodfirst.card.last4}</p>
      <p style={{ fontSize: 14 }}>{transaction.status === "succeeded" ? "Success" : (transaction.status === "requires_confirmation" ? "Confirming" : transaction.status)}</p>
      <p style={{ fontSize: 14 }}>{date}</p>
    </div>
  )
}

function SaveCardForm({ stripe, elements }) {
  const [errorMessage, seterrorMessage] = useState(false);
  const [em, setem] = useState("");
  // this is for stripe that this will get info from backend
  const [stripeuser, setstripeuser] = useState(null);
  // get saved cards
  const [nocards, setnocards] = useState(false);
  const [cards, setcards] = useState([]);
  // get payments
  const [nopayments, setnopayments] = useState(false);
  const [chosen, setchosen] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [successm, setsuccessm] = useState();

  // for transatcoins
  const [payments, setpayments] = useState([]);
  const [newpayments, setnewpayments] = useState([]);

  // for pagination
  const [lastVisible, setlastVisible] = useState();
  const [lastt, setlastt] = useState(false);
  const [dontshowcardsaver, setdontshowcardsaver] = useState(false);


  useEffect(() => {
    // need to get stripe user because there saved some info for saving card and paying then
    if (auth.currentUser !== null) {
      getDoc(doc(db, "stripe_customers", auth.currentUser.uid)).then((stripecustomer) => {
        if (stripecustomer.exists) {
          console.log(stripecustomer.data())
          setstripeuser(stripecustomer.data())
        } else {
          console.log("No stripe customer was found")
          return
        }
      }).then(() => {
        showCards()
      })
    }

  }, []);


  useEffect(() => {
    //console.log("these r length of searchedposts", payments)
    // payments.length === 1 ? setlastsearchUser(true) : null
    if (payments.length === 1) {
      setlastt(true)
    }
    const lastitem = payments.slice(-1)
    setlastVisible(lastitem[0])
    console.log("this is lastvis", lastitem[0])
  }, [payments]);

  useEffect(() => {
    //console.log("newsearchedposts.length", newsearchedUsers.length)
    newpayments.length === 1 ? setlastt(true) : setlastt(false)

    setpayments([...payments.slice(0, -1), ...newpayments])
  }, [newpayments]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    seterrorMessage(false)
    setsuccessm()
    console.log("save stripe card now", stripeuser.setup_secret, elements.getElement(CardElement))

    if (stripeuser === undefined || stripeuser === null || elements.getElement(CardElement) === undefined) {
      console.log("no stripeuser.setup_secret")
      return
    }

    const { setupIntent, error } = await stripe.confirmCardSetup(
      stripeuser.setup_secret,
      {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: "Chef 123",
          },
        },
      }
    );

    console.log("setupIntent, error", setupIntent, error)

    if (error && error.code === "card_declined") {
      console.log("There was an error saving the card", error)
      seterrorMessage(true)
      setem("Your card was declined. Please retry")
      callbeerrorchangeclientsecret()
      return
    } else if (error && error.code === "expired_card") {
      console.log("There was an error saving the card", error)
      seterrorMessage(true)
      setem("Your card expired. Please retry with another one.")
      callbeerrorchangeclientsecret()
      return
    } else if (error && error.code === "incorrect_cvc") {
      console.log("There was an error saving the card", error)
      seterrorMessage(true)
      setem("Your CVC code is wrong.")
      callbeerrorchangeclientsecret()
      return
    } else if (error && error.code === "incorrect_number") {
      console.log("There was an error saving the card", error)
      seterrorMessage(true)
      setem("Your card number is incorrect. Please retry")
      callbeerrorchangeclientsecret()
      return
    } else if (error) {
      console.log("There was an error saving the card", error)
      seterrorMessage(true)
      setem("Your card transaction didn´t work. Please retry")
      callbeerrorchangeclientsecret()
      return
    }

    //console.log("setupintent", setupIntent)

    const Ref = collection(db, 'stripe_customers', auth.currentUser.uid, "payment_methods");
    addDoc(Ref, {
      id: setupIntent.payment_method
    }).then((res) => {
      console.log("added payment method", res)
      setsuccessm("Your card was added and will show up in 1 min after verifying!")
      showCards()
    })
  };

  const callbeerrorchangeclientsecret = () => {
    console.log("this is callbeerrorchangeclientsecret", stripeuser, auth.currentUser.uid)
    const checkorderbackend = httpsCallable(functions, 'erroraddingCardCreatingNewSetupSecret');
    checkorderbackend({ userid: auth.currentUser.uid, customerid: stripeuser.customer_id })
  }

  const showCards = () => {
    setdontshowcardsaver(false)
    setpayments([])
    setcards([])
    console.log("show cards")
    let a = []

    const f = async () => {
      const q = query(collection(db, "stripe_customers", auth.currentUser.uid, "payment_methods"));
      const docs = await getDocs(q);
      if (docs.empty) {
        setnocards(true)
        return
      } else {

        docs.forEach(async (doc) => {
          console.log("first", doc.data(), !doc.data().card)
          if (!doc.data().card) {
            return
          } else {
            let obj = { ...doc.data(), firebaseid: doc.id }
            a.push(obj)
          }
        })
        setcards(a)
      }
    }

    f()
  }

  const showTransactions = () => {
    setdontshowcardsaver(true)
    setpayments([])
    setcards([])
    setnopayments(false)
    // window.location.href = "http://localhost:3000/profile"
    console.log("show trans")
    let a = []

    const f = async () => {
      const q = query(collection(db, "stripe_customers", auth.currentUser.uid, "payments"), orderBy("creation", "desc"), limit(5));
      const docs = await getDocs(q);
      if (docs.empty) {
        setnopayments(true)
        return
      } else {
        docs.forEach(async (doc) => {

          if (doc.data().status === "requires_action") {
            //handleCardAction(doc.data(), doc.id);
            a.push(doc.data())
          } else if (doc.data().status === "requires_confirmation") {
            console.log("still confirming in background")
            a.push(doc.data())
          } else if (doc.data().status === "card_declined") {
            console.log("card declined")
            a.push(doc.data())
          } else {
            a.push(doc.data())
            console.log("payment status", doc.data().status)
            // status: new, requires_confirmation, succeeded, requires_action
            // get card: payment.charges.data[0].payment_method_details.card
          }
        });
        const lastVisible = docs.docs[docs.docs.length - 1]
        a.push(lastVisible)
        setpayments(a)
      }
    }
    f()
  }

  const loadMoreTransactions = (e) => {
    e.preventDefault()
    setcards([])
    setnopayments(false)
    // window.location.href = "http://localhost:3000/profile"
    console.log("show more trans")
    let a = []

    const f = async () => {
      const q = query(collection(db, "stripe_customers", auth.currentUser.uid, "payments"), orderBy("creation", "desc"), startAfter(lastVisible), limit(5));
      const docs = await getDocs(q);
      if (docs.empty) {
        setnopayments(true)
        return
      } else {
        docs.forEach(async (doc) => {

          if (doc.data().status === "requires_action") {
            //handleCardAction(doc.data(), doc.id);
            a.push(doc.data())
          } else if (doc.data().status === "requires_confirmation") {
            console.log("still confirming in background")
            a.push(doc.data())
          } else if (doc.data().status === "card_declined") {
            console.log("card declined")
            a.push(doc.data())
          } else {
            a.push(doc.data())
            console.log("payment status", doc.data().status)
            // status: new, requires_confirmation, succeeded, requires_action
            // get card: payment.charges.data[0].payment_method_details.card
          }

        });
        const lastVisible = docs.docs[docs.docs.length - 1]
        a.push(lastVisible)
        setnewpayments(a)
      }
    }
    f()
  }

  // Handle card actions like 3D Secure
  async function handleCardAction(payment, docId) {
    console.log("handlecardaction", payment.client_secret)
    const { error, paymentIntent } = await stripe.handleCardAction(
      payment.client_secret
    )

    if (error) {
      alert(error.message);
      payment = error.payment_intent;
    } else if (paymentIntent) {
      payment = paymentIntent;
    }

    console.log("thos is payment in handleCardAction", payment)
    const Ref = collection(db, 'stripe_customers', auth.currentUser.uid, "payments", docId);
    await updateDoc(Ref, {
      payment
    }).then((res) => {
      //console.log("handleCardAction", res)
    })
  }

  return (
    <form style={{ alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column" }}>
      {dontshowcardsaver
        ?
        null :
        (cards.length > 2 ?
          <div style={{ color: "red", fontSize: 14, marginTop: 10 }}>You can save at max 3 cards. Please delete one to save a new one.</div>
          :
          <>
            <div style={{ marginTop: 10, marginBottom: 10, backgroundColor: "lightblue", padding: 10, width: "90%", borderRadius: 15 }}>
              <CardElement showIcon={true} />
            </div>
            <button onClick={(e) => handleSubmit(e)} style={{ backgroundColor: "#009ffd", color: "white", border: "none", height: 40, width: 100, borderRadius: 20, fontSize: 17 }} disabled={!stripe}>
              Save
            </button>
            {successm && <p style={{ color: "green", marginTop: 15, fontSize: 14 }}>{successm}</p>}
            {errorMessage && <div style={{ color: "red", fontSize: 14, marginTop: 10, textAlign: "center" }}>{em}</div>}
          </>)
      }

      <div style={{ alignItems: "center", justifyContent: "space-around", display: "flex", flexDirection: "row", marginTop: 20, width: "100%" }}>
        <div onClick={() => showCards()} style={{ alignItems: "center", justifyContent: "center", display: "flex", backgroundColor: "#009ffd", color: "white", border: "none", height: 30, width: 150, borderRadius: 20, fontSize: 15 }} disabled={!stripe}>
          <p style={{ color: "white" }}>Show Cards</p>
        </div>
        <div onClick={() => showTransactions()} style={{ alignItems: "center", justifyContent: "center", display: "flex", backgroundColor: "#009ffd", color: "white", border: "none", height: 30, width: 150, borderRadius: 20, fontSize: 15 }} disabled={!stripe}>
          <p style={{ color: "white" }}>Load Transactions</p>
        </div>
      </div>

      {cards.length !== 0 ?
        <div style={{ width: "100%", marginTop: 15, maxHeight: 400, overflow: "scroll", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          {cards.map((card, i) => (
            <CardRow card={card} index={i} setchosen={setchosen} chosen={chosen} showCards={showCards} />
          ))}
        </div>
        :
        null
      }

      {payments.length !== 0 ?
        <div style={{ width: "100%", paddingTop: 20, height: 300, marginTop: 15, overflow: "scroll", display: "flex", flexDirection: "column", }}>
          {payments.slice(0, -1).map((transaction, i) => (
            <TransactionRow transaction={transaction} />
          ))}
          {lastt ? null :
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <button onClick={(e) => loadMoreTransactions(e)} style={{ backgroundColor: "#009ffd", color: "white", border: "none", height: 30, width: 180, borderRadius: 20, fontSize: 17 }} disabled={!stripe}>
                Load More
              </button>
            </div>
          }
        </div>
        : null
      }

      {nocards ? <p style={{ color: "red", marginTop: 15, fontSize: 14 }}>You don´t have any saved cards yet</p> : null}
      {nopayments ? <p style={{ color: "red", marginTop: 15, fontSize: 14 }}>You don´t have any payments yet</p> : null}

    </form>
  );

}

export default SaveCardForm;