import React, { useEffect } from 'react';
import './App.css';
import HomeScreen from './screens/HomeScreen/HomeScreen';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginScreen from './screens/Loginscreen/LoginScreen';
import { auth } from './firebase';
import { useDispatch, useSelector } from 'react-redux';
import { login, logout, selectUser } from './features/userSlice';
import ProfileScreen from './screens/ProfileScreen/ProfileScreen';
import UserScreen from './screens/UserScreen/UserScreen';
import PostScreen from './screens/PostScreen/PostScreen';
import { getCurrentUserData } from './services/f';
import { nouser } from './features/currentUser';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import SearchScreen from './screens/SearchScreen/SearchScreen';
import ErrorPage from './ErrorPage/Error';
import TermsOfService from './screens/Legal/termsofservice';
import CommunityGuidelines from './screens/Legal/communityguidelines';
import FAQ from './screens/Legal/faq';
import PrivacyPolicy from './screens/Legal/privacypolicy';
import DownloadAppScreen from './screens/DownloadAppScreen/DownloadAppScreen';
import GroupScreen from './screens/GroupScreen/GroupScreen';
import PremiumMessage from './screens/PremiumMessageScreen/PremiumMessage';
import PremiumMessageBought from './screens/PremiumMessageScreenBought/PremiumMessageBought';
import CreateContentScreen from './screens/CreateContentScreen/CreateContentScreen';
import PostOnPremsi from './screens/PostOnPremsi/PostOnPremsi';
import PremsiPresentation from './screens/PremsiPresentationScreen/PremsiPresentationScreen';
import ExplanationScreen from './screens/ExplanationScreen/ExplanationScreen';
import PremsiCalculator from './screens/PremsiCalculator/PremsiCalculatorScreen';
import CreatePremiumMessageScreen from './screens/CreatePremiumMessageScreen/CreatePremiumMessageScreen';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import VerifyPaymentScreen from './screens/ProfileScreen/StripeFolder/VerifyPaymentForPhone';

export const stripePromise = loadStripe('pk_live_51MhVH7G1QidUmMTH7aRHJXL2x322BgejB5HF3tBS4IWPnD1SW90IReG01noHZWOOsWuEvl2SfW6v6KkRkuaiCM7J00Ib09gw3b');
// pk_test_51MhVH7G1QidUmMTHZcQXHYPAPnTvKHb2DkTPSUArUfrpdCoCILrG7VnoxWx2wu8MErCScVc28cnd6PsCmQBrW50X00X5wN0yqv
// pk_live_51MhVH7G1QidUmMTH7aRHJXL2x322BgejB5HF3tBS4IWPnD1SW90IReG01noHZWOOsWuEvl2SfW6v6KkRkuaiCM7J00Ib09gw3b
const InjectedVerifyPaymentScreen = () => (
  <ElementsConsumer>
    {({ stripe, elements }) => (
      <VerifyPaymentScreen stripe={stripe} elements={elements} />
    )}
  </ElementsConsumer>
);

function App() {
  let user = useSelector(selectUser)// ALSO WENNS n user gibt dann wird anderes screen angezeigt
  //console.log("this is", useSelector(selectUser))
  const dispatch = useDispatch()

  useEffect(() => {
    //selectUser()
    const unsub = auth.onAuthStateChanged((userAuth) => {
      if (userAuth) {
        //they are logged in
        //console.log("user logged in")
        // to get that info then is let user = useSelector(selectUser)// ALSO WENNS n user gibt dann wird anderes screen angezeigtƒ
        dispatch(login({
          uid: userAuth.uid,
          email: userAuth.email,
        }))
        dispatch(getCurrentUserData())
      } else {
        // they are logged out 
        // yes
        //console.log("user logged out")
        dispatch(logout())
        dispatch(nouser())
      }
    });
    return unsub
  }, [dispatch]);
  // in PayPalScriptProvider can also set other value in options see https://developer.paypal.com/sdk/js/configuration/#link-configureandcustomizeyourintegration
  // uncomment clientid

  return (
    <PayPalScriptProvider options={{ dataNamespace: "paypalcheckout", disableFunding: "paylater,sepa,card", clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID }}>
      <Elements stripe={stripePromise} >
        <div className="app">
          <BrowserRouter>
            {user === undefined || user === null ?
              (
                <Routes>
                  <Route path="/" element={<LoginScreen />} />
                  <Route path="/app" element={<LoginScreen />} />
                  <Route path="/sell" element={<CreateContentScreen />} />
                  <Route path="/presentation" element={<PremsiPresentation />} />
                  <Route path="/explanation" element={<ExplanationScreen />} />
                  <Route path="/postonpremsi" element={<PostOnPremsi />} />
                  <Route path="/user" element={<UserScreen />} />
                  <Route path="/calculator" element={<PremsiCalculator />} />
                  <Route path="/createpremiummessage" element={<CreatePremiumMessageScreen />} />

                  <Route path="/post" element={<PostScreen />} />
                  <Route path="/group" element={<GroupScreen />} />
                  <Route path="/pm" element={<PremiumMessage />} />
                  <Route path="/boughtpm" element={<PremiumMessageBought />} />
                  <Route path="/search" element={<SearchScreen />} />
                  <Route path="*" element={<ErrorPage />} /> {/* in order to have an error page show up everytime a link doesnt work */}
                  <Route path="/termsofservice" element={<TermsOfService />} />
                  <Route path="/communityguidelines" element={<CommunityGuidelines />} />
                  <Route path="/faq" element={<FAQ />} />
                  <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                  <Route path='/verifypayment' element={<InjectedVerifyPaymentScreen />} />
                </Routes>
              )
              :
              (
                <Routes>
                  <Route path="/" element={<HomeScreen />} />
                  <Route path="/app" element={<DownloadAppScreen />} />
                  <Route path="/sell" element={<CreateContentScreen />} />
                  <Route path="/presentation" element={<PremsiPresentation />} />
                  <Route path="/explanation" element={<ExplanationScreen />} />
                  <Route path="/postonpremsi" element={<PostOnPremsi />} />
                  <Route path="/profile" element={<ProfileScreen />} />
                  <Route path="/user" element={<UserScreen />} />
                  <Route path="/calculator" element={<PremsiCalculator />} />
                  <Route path="/createpremiummessage" element={<CreatePremiumMessageScreen />} />
                  <Route path="/post" element={<PostScreen />} />
                  <Route path="/group" element={<GroupScreen />} />
                  <Route path="/pm" element={<PremiumMessage />} />
                  <Route path="/boughtpm" element={<PremiumMessageBought />} />
                  <Route path="/search" element={<SearchScreen />} />
                  <Route path="*" element={<ErrorPage />} /> {/* in order to have an error page show up everytime a link doesnt work */}
                  <Route path="/termsofservice" element={<TermsOfService />} />
                  <Route path="/communityguidelines" element={<CommunityGuidelines />} />
                  <Route path="/faq" element={<FAQ />} />
                  <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                  <Route path='/verifypayment' element={<InjectedVerifyPaymentScreen />} />
                </Routes>
              )
            }
          </BrowserRouter>
        </div>
      </Elements>
    </PayPalScriptProvider>
  );
}

export default App;
