import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Error.css";
import Nav from "../components/Nav/Nav";
import { useSelector } from "react-redux";
import { selectUser } from "../features/userSlice";
import Footer from "../components/Footer";
import { auth } from "../firebase";
import VerifyEmailComponent from "../components/rememberverifyemail";
import { Helmet } from "react-helmet";
import NavForNotHome from '../components/Nav/NavForNotHome';

const ErrorPage = () => {
  let user = useSelector(selectUser)// ALSO WENNS n user gibt dann wird anderes screen angezeigt
  let navigate = useNavigate()

  if (user === undefined || user === null) {
    return (
      <>
        <Helmet>
          {/* Open Graph Meta Tags */}
          <meta property="og:title" content="Premsi - Wrong page 😢" />
          <meta property="og:description" content="Premsi | You tried to access a page that doesn't exist . Premsi lets you easily sell and market your Videos/Pics/PDFs/Groups and soon courses. All for free and in 1 minute." />
          <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/premsicom-accb2.appspot.com/o/P1.png?alt=media&token=85d8afdb-d43d-4c28-9c1b-862ea8659fad" />
          <meta property="og:site_name" content="Premsi - sell digital content" />

          <meta name="description" content="Premsi | You tried to access a page that doesn't exist . Premsi lets you easily sell and market your Videos/Pics/PDFs/Groups and soon courses. All for free and in 1 minute." />
          <title>Premsi - Wrong page</title>
        </Helmet>
        <NavForNotHome />
        <div className='loginscreen'>
          <div style={{ width: "100%", height: "100vh", backgroundColor: "rgba(0, 0, 0, 0.5)", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div className='loginscreen__body_error'>

              <h1 className="errorh2" style={{ width: "80%", color: "white" }}>You're trying to access a site that doesn't exist or you don't have access to..</h1>
              <h2 className="errorh3" style={{ color: "white" }}>Please login to discover all features.</h2>
              <h4 className="errorh4" style={{ color: "white" }}>Enjoy your favourite premium content. Ready to join?</h4>
              <div className='loginscreen__input'>
                <button style={{ borderRadius: 10, }} onClick={() => navigate("/")} className='loginscreen__getStarted'>START ENJOYING</button>
              </div>

            </div>
          </div >
        </div >

        <Footer />
      </>
    )
  }

  return (
    <div className='errorpage'>
      {/* NavBar */}
      <Nav />
      {auth.currentUser === null || auth.currentUser === undefined || auth.currentUser.emailVerified === true ? null : <VerifyEmailComponent />}

      <div style={{ paddingTop: 0, height: "80vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>

        <div className='loginscreen__body_error_loggedin'>
          <img loading="lazy" className="error_pic" src={require('../images/error.webp')} alt="Premsi.com Error" />
          <h3 className="errorh3">You're trying to access a site that doesn't exist or you don't have access to..</h3>
          <button style={{ borderRadius: 10, marginTop: 20, marginBottom: 20 }} onClick={() => navigate("/")} className='loginscreen__getStarted'>Return</button>
        </div>

      </div>

      <Footer />
    </div>
  );
}

export default ErrorPage;

