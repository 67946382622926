import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom'; //https://stackoverflow.com/questions/42173786/react-router-pass-data-when-navigating-programmatically
//<Link to={{ pathname: '/post', state: { id: 1, name: 'sabaoon', shirt: 'green' } }} >Learn More</Link>
import './UserScreen.css';
import Nav from '../../components/Nav/Nav'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUser } from '../../features/currentUser'
import { Oval, MagnifyingGlass } from 'react-loader-spinner'
import { getGroupsByUserId, getPremiumMessagesOfUser, getUserProfileInfoWithId, getUsersLastSixPosts } from '../../services/f';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry" //https://youtu.be/RDxnu8Xt5Rk + https://cedricdelpoux.github.io/react-responsive-masonry/
import { BsBrowserSafari, BsPlusCircle, BsInfoCircle, BsInstagram, BsFillCheckCircleFill, BsTiktok, BsTwitter, BsYoutube } from 'react-icons/bs'  //https://react-icons.github.io/react-icons
import { MdErrorOutline } from 'react-icons/md'
import { TbCircleLetterP } from 'react-icons/tb'
import { PiUserCircleLight } from 'react-icons/pi'
import Footer from '../../components/Footer';
import { auth } from '../../firebase';
import VerifyEmailComponent from '../../components/rememberverifyemail';
import { Helmet } from 'react-helmet';
import { HiOutlineLockClosed } from "react-icons/hi";
import { IoIosArrowDropright } from 'react-icons/io'

function UserScreen() {
  // http://localhost:3000/user?id=sdffsdf
  // http://localhost:3000/user?id=MFbwMsG0SkVnOYTUMZRKeshqBmA2
  const [searchParams, setSearchParams] = useSearchParams();
  let currentUser = useSelector(getCurrentUser).currentUser?.currentUser// ALSO WENNS n user gibt dann wird anderes screen angezeigt
  const navigate = useNavigate()

  const [userProfile, setuserProfile] = useState(null);
  const [showpremiumposts, setshowpremiumposts] = useState(0);
  const [showmoreinfo, setshowmoreinfo] = useState(false);

  const [userPosts, setuserPosts] = useState(["null"]);
  const [groups, setgroups] = useState([]);

  const [pmtype, setpmtype] = useState();
  const [premiummessages, setpremiummessages] = useState([]);

  useEffect(() => {
    // check if user or psot available then query for them and show them
    // get specific   searchParams.get("code")

    let userid = searchParams.get("id")
    if (userid === null || userid === undefined || userid === "") {
      setuserProfile("usernotexists")
    } else {
      getUserProfileInfoWithId(userid).then((res) => {
        // console.log("getUserProfileInfoWithId result", res)
        setuserProfile(res)
        getUsersLastSixPosts(userid, currentUser).then((posts) => {
          //console.log("these are getUsersLastSixPosts", posts)
          setuserPosts(posts)
        })
      })
    }

    let fasttrack = searchParams.get("t")
    if (fasttrack === null || fasttrack === undefined || fasttrack === "") {
      return
    } else {
      setshowpremiumposts(2)
    }
  }, []);

  useEffect(() => {
    if (showpremiumposts === 3) {
      getGroupsByUserId(searchParams.get("id"), currentUser).then((gottengroups) => {
        // console.log("these are user groups", gottengroups)
        if (gottengroups === "empty") {
          return
        } else {
          setgroups(gottengroups)
        }
      })
    } else {
      return
    }
  }, [showpremiumposts]);

  useEffect(() => {
    if (pmtype === 0 && showpremiumposts === 2) {
      //console.log("PMTYPE", pmtype)
      getPremiumMessagesOfUser(auth.currentUser.uid, "image").then(setpremiummessages)

    } else if (pmtype === 1 && showpremiumposts === 2) {
      //console.log("PMTYPE", pmtype)
      getPremiumMessagesOfUser(auth.currentUser.uid, "video").then(setpremiummessages)

    } else if (pmtype === 2 && showpremiumposts === 2) {
      //console.log("PMTYPE", pmtype)
      getPremiumMessagesOfUser(auth.currentUser.uid, "pdf").then(setpremiummessages)

    }
  }, [pmtype]);

  useEffect(() => {
    // console.log("first", premiummessages)
  }, [premiummessages]);

  const formatNumber = n => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(2) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(3) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
  };
  let v = "Checkout what content " + userProfile?.displayName + " posted." + "Sell and market digital content in seconds . Premsi lets you easily sell and market your Videos/Pics/PDFs/Groups and soon courses. All for free and in 1 minute."
  let titletext = "Premsi - User: " + userProfile?.displayName
  return (
    <div className='userscreen'>
      <Helmet>

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Premsi | User - Sell digital content in minutes❤️" />
        <meta property="og:description" content="Premsi | Sell and market digital content in seconds. Download the Premsi App for more features. Premsi lets you easily sell and market your Videos/Pics/PDFs/Groups and soon courses. All for free and in 1 minute." />
        <meta property="og:url" content="https://premsi.com/user" />
        <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/premsicom-accb2.appspot.com/o/P1.png?alt=media&token=85d8afdb-d43d-4c28-9c1b-862ea8659fad" />
        <meta property="og:site_name" content="Premsi - sell digital content" />
        <link rel="canonical" href="https://premsi.com/user" />

        <title>{titletext}</title>
        <meta name="description" content={String(v)} />
      </Helmet>
      <Nav />
      {auth.currentUser === null || auth.currentUser === undefined || auth.currentUser.emailVerified === true ? null : <VerifyEmailComponent />}

      <div className='userscreen__body'>

        {userProfile === null ?
          <div>
            <Oval
              height={40}
              width={40}
              color="#009FFD"
              wrapperStyle={{ marginTop: 20, flex: "display", alignItems: "center", justifyContent: "center" }}
              wrapperClass=""
              visible={true}
              ariaLabel='oval-loading'
              secondaryColor="white"
              strokeWidth={5}
              strokeWidthSecondary={5}
            />
            <p style={{ marginBottom: 3, textAlign: "center" }}>Loading user...</p>
            <p style={{ textAlign: "center", fontSize: 12 }}>If this takes too long please refresh this window.</p>
          </div>
          :
          (
            userProfile === "usernotexists"
              ?
              <div>
                <MagnifyingGlass
                  height={40}
                  width={40}
                  color="#009FFD"
                  wrapperStyle={{ marginTop: 20, width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}
                  wrapperClass=""
                  visible={true}
                  ariaLabel='oval-loading'
                  secondaryColor="white"
                  strokeWidth={5}
                  strokeWidthSecondary={5}
                />
                <p style={{ marginBottom: 3, textAlign: "center" }}>  No user...</p>
                <p style={{ textAlign: "center", fontSize: 12 }}>It seems that you have a wrong link and this user doesn't exist</p>
                <p style={{ textAlign: "center", fontSize: 12 }}>Please recheck your link.</p>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", marginTop: 5, marginBottom: 5 }}>
                  <button onClick={() => navigate("/")} style={{ fontSize: 12, paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5, borderRadius: 10, backgroundColor: "white", borderWidth: 0 }}>Explore more</button>
                </div>
              </div>
              :
              <>
                <div className='userscreen__info'>

                  {userProfile?.photoUrl === "" || userProfile === null || userProfile === undefined
                    ?
                    <div className='avatar_nopic_profile_div'>
                      <PiUserCircleLight className='avatar_nopic_profile' size={38} color='black' alt='no profile picture' />
                    </div>
                    :
                    <img loading="lazy" style={{ pointerEvents: "none" }} className='avatar_profile' src={userProfile?.photoUrl} alt="Premsi user pic" />
                  }

                  <div className='userscreen__details'>

                    <div className='userscreen__topuserinfo'>

                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingTop: 10, height: 30 }}>
                        <h2 className='useremail'>{userProfile?.displayName}</h2>
                        {userProfile?.verified
                          ?
                          <div style={{ marginLeft: 5, height: 22, width: 22, borderRadius: 30, backgroundColor: "white", alignItems: "center", justifyContent: "center" }}>
                            <BsFillCheckCircleFill size={22} color='blue' alt='verified account' />
                          </div>
                          :
                          null
                        }
                      </div>

                      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", height: 80, paddingLeft: 20, paddingRight: 20 }}>

                        <div className='usertopinfotext'>
                          <p style={{ fontWeight: "bold" }}>Followers</p>
                          <p style={{ marginTop: 5 }}>{formatNumber(userProfile?.followers)}</p>
                        </div>

                        <div className='usertopinfotext'>
                          <p style={{ fontWeight: "bold" }}>Following</p>
                          <p style={{ marginTop: 5 }}>{formatNumber(userProfile?.followingamount)}</p>
                        </div>

                        <div className='usertopinfotext'>
                          <p style={{ fontWeight: "bold" }}>Premium Posts</p>
                          <p style={{ marginTop: 5 }}>{formatNumber(userProfile?.amountpremiumposts)}</p>
                        </div>

                      </div>

                    </div>

                  </div>
                </div>

                {userProfile?.profiles[0] === "" && userProfile?.profiles[1] === "" && userProfile?.profiles[2] === "" && userProfile?.profiles[3] === "" && userProfile?.profiles[4] === "" && userProfile?.creatorinfo === "" ?
                  null :
                  <div className='userbioinfo'>
                    {userProfile?.creatorinfo === "" ? null : <p>{userProfile?.creatorinfo}</p>}

                    {userProfile?.profiles[0] === "" && userProfile?.profiles[1] === "" && userProfile?.profiles[2] === "" && userProfile?.profiles[3] === "" && userProfile?.profiles[4] === ""
                      ?
                      null
                      :
                      <div className='userProfilesocials'>
                        <div className='inneruserProfilesocials'>
                          {userProfile?.profiles.map((link, i) => (
                            (link === ""
                              ?
                              null
                              :
                              (i === 0 ?
                                <a className='socialicon' href={link}><BsInstagram size={23} color='white' /></a>
                                :
                                (i === 1 ?
                                  <a className='socialicon' href={link}><BsYoutube size={23} color='white' /></a>
                                  : (i === 2 ?
                                    <a className='socialicon' href={link}><BsTwitter size={23} color='white' /></a>
                                    :
                                    (i === 3 ?
                                      <a className='socialicon' href={link}><BsTiktok size={23} color='white' /></a>
                                      :
                                      <a className='socialicon' href={link}><BsBrowserSafari size={23} color='white' /></a>
                                    )
                                  )
                                )
                              )

                            )
                          ))}
                        </div>
                      </div>}

                  </div>
                }

                <h2 className='belowuserinfo'>To chat, follow, like, comment etc. with {userProfile?.displayName} please <a href="/" style={{ color: "white" }}>download</a> our App.</h2>

                <div className='userPosts'>

                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around", marginBottom: 10 }}>
                    <button onClick={() => setshowpremiumposts(0)} className='profbtn' style={{ border: 0, color: "white", cursor: "pointer", paddingLeft: 15, paddingRight: 15, display: "flex", alignItems: "center", justifyContent: "center", height: 40, borderRadius: 20, backgroundColor: (showpremiumposts === 0 ? "#009FFD" : "rgba(128, 128, 128, 0.8)"), fontWeight: "bold" }}>Normal Posts</button>
                    <button onClick={() => setshowpremiumposts(1)} className='profbtn' style={{ border: 0, color: "white", cursor: "pointer", paddingLeft: 15, paddingRight: 15, display: "flex", alignItems: "center", justifyContent: "center", height: 40, borderRadius: 20, backgroundColor: (showpremiumposts === 1 ? "#009FFD" : "rgba(128, 128, 128, 0.8)"), fontWeight: "bold" }}>Premium Posts</button>
                    <button onClick={() => setshowpremiumposts(2)} className='profbtn' style={{ border: 0, color: "white", cursor: "pointer", paddingLeft: 15, paddingRight: 15, display: "flex", alignItems: "center", justifyContent: "center", height: 40, borderRadius: 20, backgroundColor: (showpremiumposts === 2 ? "#009FFD" : "rgba(128, 128, 128, 0.8)"), fontWeight: "bold" }}>Premium Messages</button>
                    <button onClick={() => setshowpremiumposts(3)} className='profbtn' style={{ border: 0, color: "white", cursor: "pointer", paddingLeft: 15, paddingRight: 15, display: "flex", alignItems: "center", justifyContent: "center", height: 40, borderRadius: 20, backgroundColor: (showpremiumposts === 3 ? "#009FFD" : "rgba(128, 128, 128, 0.8)"), fontWeight: "bold" }}>Groups</button>
                  </div>

                  {showpremiumposts === 2 ?
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around", marginBottom: 0 }}>
                      <button onClick={() => setpmtype(0)} className='profbtn' style={{ border: 0, color: "white", cursor: "pointer", paddingLeft: 15, paddingRight: 15, display: "flex", alignItems: "center", justifyContent: "center", height: 30, borderRadius: 20, backgroundColor: (pmtype === 0 ? "#009FFD" : "rgba(128, 128, 128, 0.8)"), fontWeight: "bold" }}>Pictures</button>
                      <button onClick={() => setpmtype(1)} className='profbtn' style={{ border: 0, color: "white", cursor: "pointer", paddingLeft: 15, paddingRight: 15, display: "flex", alignItems: "center", justifyContent: "center", height: 30, borderRadius: 20, backgroundColor: (pmtype === 1 ? "#009FFD" : "rgba(128, 128, 128, 0.8)"), fontWeight: "bold" }}>Videos</button>
                      <button onClick={() => setpmtype(2)} className='profbtn' style={{ border: 0, color: "white", cursor: "pointer", paddingLeft: 15, paddingRight: 15, display: "flex", alignItems: "center", justifyContent: "center", height: 30, borderRadius: 20, backgroundColor: (pmtype === 2 ? "#009FFD" : "rgba(128, 128, 128, 0.8)"), fontWeight: "bold" }}>PDFs</button>
                    </div>
                    : null}

                  {showpremiumposts === 1
                    ?
                    (currentUser?.premium === true && currentUser?.premiumend > (new Date().getTime() / 1000)
                      ?
                      <div className='premiumavailableparent'>

                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                          <TbCircleLetterP size={22} color='green' alt='not subscribed' />
                          <p style={{ marginLeft: 5, fontSize: 12 }}>Thank you for having subscribed to Premsi Premium.</p>
                        </div>

                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 10, paddingLeft: 20, paddingRight: 20 }}>
                          <p style={{ fontSize: 14, fontWeight: "bold" }}>Now you can see all premium posts of all creators + a lot of benefits in our App!</p>
                        </div>

                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 10, paddingLeft: 20, paddingRight: 20 }}>
                          <button onClick={() => (navigate("/app"))} style={{ cursor: "pointer", fontSize: 14, height: 40, paddingLeft: 15, paddingRight: 15, borderRadius: 20, borderWidth: 0, fontWeight: "bold" }}>Download our App</button>
                        </div>

                      </div>
                      :
                      (currentUser === null || currentUser === undefined
                        ?
                        <div className='premiumnotavailableparent'>

                          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <MdErrorOutline size={22} color='red' alt='not subscribed' />
                            <p style={{ marginLeft: 5, fontSize: 12 }}>Unfortunately, you are not logged in.</p>
                          </div>

                          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 10, paddingLeft: 20, paddingRight: 20 }}>
                            <p style={{ fontSize: 14, fontWeight: "bold" }}>Login to be able to see the premium posts of all creators.</p>
                          </div>

                          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 10, paddingLeft: 20, paddingRight: 20 }}>
                            <button onClick={() => navigate("/")} style={{ cursor: "pointer", marginRight: 5, fontSize: 14, height: 40, paddingLeft: 15, paddingRight: 15, borderRadius: 20, borderWidth: 0, fontWeight: "bold" }}>All in one premium for only 3.49$</button>
                            <BsInfoCircle style={{ cursor: "pointer" }} onClick={() => setshowmoreinfo(!showmoreinfo)} size={17} color='white' />
                          </div>

                          {showmoreinfo ?
                            <>
                              <div style={{ display: "flex", alignItems: "center", textAlign: "center", justifyContent: "center", marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
                                <p style={{ fontSize: 15, fontWeight: "bold" }}>Correct! Our 3.49$ subscription is an universal subscription for all the premium posts of our creators.</p>
                              </div>

                              <div style={{ display: "flex", alignItems: "center", textAlign: "center", justifyContent: "center", marginTop: 3, paddingLeft: 20, paddingRight: 20 }}>
                                <p style={{ fontSize: 15, fontWeight: "bold" }}>There's no need anymore to individually subscribe to your favorite creator.</p>
                              </div>
                            </>
                            :
                            null}

                        </div>
                        :
                        <div className='premiumnotavailableparent'>

                          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <MdErrorOutline size={22} color='red' alt='not subscribed' />
                            <p style={{ marginLeft: 5, fontSize: 12 }}>Unfortunately, you don`t have our premium subscription.</p>
                          </div>

                          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 10, paddingLeft: 20, paddingRight: 20 }}>
                            <p style={{ fontSize: 14, fontWeight: "bold" }}>Get Premsi Premium for 3.49$ and see the premium posts of all creators.</p>
                          </div>

                          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 10, paddingLeft: 20, paddingRight: 20 }}>
                            <button onClick={() => navigate("/profile")} style={{ cursor: "pointer", marginRight: 5, fontSize: 14, height: 40, paddingLeft: 15, paddingRight: 15, borderRadius: 20, borderWidth: 0, fontWeight: "bold" }}>Get it now for 3.49$</button>
                            <BsInfoCircle style={{ cursor: "pointer" }} onClick={() => setshowmoreinfo(!showmoreinfo)} size={17} color='white' />
                          </div>

                          {showmoreinfo ?
                            <>
                              <div style={{ display: "flex", alignItems: "center", textAlign: "center", justifyContent: "center", marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
                                <p style={{ fontSize: 15, fontWeight: "bold" }}>Correct! Our 3.49$ subscription is an universal subscription for all the premium posts of our creators.</p>
                              </div>

                              <div style={{ display: "flex", alignItems: "center", textAlign: "center", justifyContent: "center", marginTop: 3, paddingLeft: 20, paddingRight: 20 }}>
                                <p style={{ fontSize: 15, fontWeight: "bold" }}>There's no need anymore to individually subscribe to your favorite creator.</p>
                              </div>
                            </>
                            :
                            null}

                        </div>)
                    )
                    :
                    (showpremiumposts === 0
                      ?
                      (userPosts[0] === "null"
                        ?
                        null
                        :
                        <>
                          <ResponsiveMasonry
                            columnsCountBreakPoints={{ 150: 2, 500: 3, 700: 4, 900: 5, 1250: 6 }} here change
                          >
                            <Masonry gutter='5px'>
                              {userPosts?.map((post, i) => (
                                <img
                                  loading="lazy"
                                  key={i}
                                  src={post[1].type === "image" ? post[1]?.media[0] : post[1]?.media[1]}
                                  style={{ width: "100%", cursor: "pointer", display: "block", aspectRatio: 12 / 16, borderRadius: 10 }}
                                  onError={(i) => (i.target.src = require('../../images/pblue-min.webp'))}
                                  onClick={() => navigate("/post?id=" + post[0])}
                                  alt={"Premsi Post" + post[1]?.description}
                                />
                              ))}
                            </Masonry>
                          </ResponsiveMasonry>
                          {userPosts?.length === 0 ?
                            <div style={{ color: "black", marginTop: 20, textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }}>
                              This user doesn't have posts yet 😢
                            </div>
                            :
                            <div onClick={() => (navigate("/app"))} style={{ cursor: "pointer", display: "flex", marginTop: 20, justifyContent: "center", alignItems: "center" }}>
                              <BsPlusCircle size={40} color="#009FFD" />
                            </div>}
                        </>
                      )
                      :
                      (showpremiumposts === 2
                        ?
                        <div className='premiumavailableparent'>

                          {premiummessages.length === 0
                            ?
                            <div style={{ color: "black", marginBottom: 20, textAlign: "center", }}>This user doesn't have any premium messages in this category yet 😢</div>
                            :
                            [...premiummessages]?.map((pm, index) => (
                              <div className='pmitem' onClick={() => navigate(`/pm?id=${pm[0]}`)}>
                                <HiOutlineLockClosed size={23} color='#009FDD' alt='private Premsi premium message' />                                <div style={{ width: "60%" }}>
                                  <p style={{ textAlign: "center", maxLines: 1 }}>{pm[1].description}</p>
                                </div>

                                <div style={{ height: 30, marginRight: 0, width: 15, borderRadius: 15, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                  <p style={{ color: "#009FDD", textAlign: "center", fontWeight: "bold" }}>{pm[1].price}$</p>
                                </div>

                                <div style={{ right: 10, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                  <IoIosArrowDropright size={17} color='#009FDD' alt='verified account' />
                                </div>

                              </div>
                            )
                            )
                          }

                          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 10, paddingLeft: 20, paddingRight: 20 }}>
                            <p style={{ fontSize: 14, fontWeight: "bold" }}>Please download our App to see and buy them + a lot of other benefits!</p>
                          </div>

                          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
                            <button onClick={() => (navigate("/app"))} style={{ cursor: "pointer", fontSize: 14, height: 40, paddingLeft: 15, paddingRight: 15, borderRadius: 20, borderWidth: 0, fontWeight: "bold" }}>Download our App</button>
                          </div>

                        </div>
                        :
                        <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                          {groups.length === 0
                            ?
                            <div style={{ color: "black", marginTop: 15, textAlign: "center", }}>This user doesn't have any groups yet 😢</div>
                            :
                            [...groups]?.map((group, index) => (
                              <div className='groupitem'>
                                <img loading="lazy" onClick={() => navigate(`/group?id=${group[0]}`)} className="groupimg" src={group[1].media} alt="Premsi.com paid group" />
                                <div style={{ width: "60%" }}>
                                  <p style={{ textAlign: "center", fontWeight: "bold" }}>{group[1].grouptitle}</p>
                                  <p style={{ textAlign: "center" }}>{group[1].description}</p>
                                </div>
                                <div onClick={() => navigate(`/group?id=${group[0]}`)} className='viewgroupbtn'>View</div>
                              </div>
                            )
                            )
                          }
                        </div>
                      )
                    )
                  }

                </div>

                {showpremiumposts === 0 ? <h2 className='belowpostsinfo'> You have seen {userProfile?.displayName}'s latest posts. To see more posts please <a href="app" style={{ color: "white" }}>download</a> our App.</h2> : null}
                {showpremiumposts === 3 ? <h2 className='belowpostsinfo'> To join {userProfile?.displayName}'s groups <a href="app" style={{ color: "white" }}>download</a> our App.</h2> : null}

              </>
          )
        }
      </div>

      <Footer />

    </div>
  )
}

export default UserScreen
