import { auth, functions } from "../firebase";
import { httpsCallable } from 'firebase/functions'

export const neo4jcreateUserNode = (displayname) => new Promise((resolve, reject) => {
    //console.log("neo4jcreateUserNode", auth.currentUser.uid)
    const neo4jUserNode = httpsCallable(functions, 'neo4jcreateUserNode');
    neo4jUserNode({ user: auth.currentUser.uid, displayName: displayname })
        .then((result) => {
            // Read result of the Cloud Function.
          // console.log("resulttt neo4jcreateUserNode", result)
            if (result.data === "success") {
                
                resolve("success")
            } else {
                resolve("error")
            }
        })
        .catch((error) => {
            // Getting the Error details.
            const code = error.code;
            const message = error.message;
            const details = error.details;
          //  console.log("errorcloudfunction", error)
            resolve("error")
        });
})