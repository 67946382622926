import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useNavigation } from 'react-router-dom';
import './Nav.css'
import { getCurrentUser } from '../../features/currentUser';
import { useSelector } from 'react-redux';
import { FiSearch } from 'react-icons/fi'
import { PiUserCircleLight } from 'react-icons/pi'
import { FaBars, FaTimes } from "react-icons/fa";
import { auth } from '../../firebase';

function NavForHome({ signin, setsignin }) {
    //console.log("this is currentusr", currentUser)
    const [searchtext, setsearchtext] = useState("false");

    const navigate = useNavigate()
    const navRef = useRef();

    const showNavbar = () => {
        navRef.current.classList.toggle(
            "responsive_nav"
        );
    };
    return (
        <header>
            <img loading="lazy" onClick={() => (navigate("/"), setsignin(false))} className="nav__logo" src={require('../../images/pblue-min.webp')} alt="Premsi.com logo app icon" />
            <nav ref={navRef}>


                {/* <p onClick={() => navigate("/")} className='navbtns' >Home</p>*/}
                {/*auth.currentUser === null || auth.currentUser === undefined ? <p onClick={() => navigate("/")} className='navbtns' >Login for more options</p> : null*/}
                <p onClick={() => navigate("/createpremiummessage")} className='navbtns' >Sell</p>
                <p onClick={() => navigate("/postonpremsi")} className='navbtns' >Post</p>

                <form onSubmit={(e) => (e.preventDefault(), navigate("/search?text=" + searchtext))} className='searchform' style={{ color: "white" }}>
                    <input onChange={(e) => setsearchtext(e.target.value)} className='searchinput' type='search' placeholder='Search Users/Groups/Premium Messages' />
                    <FiSearch onClick={(e) => (e.preventDefault(), navigate("/search?text=" + searchtext))} className='searchicon' size={26} color='white' alt='not subscribed' />
                </form>

                {auth.currentUser === null || auth.currentUser === undefined ? <button className='loginscreen__button' onClick={() => (setsignin(!signin), showNavbar())}>{!signin ? "Sign In" : "Go Back"}</button> : <button className='loginscreen__button' onClick={() => navigate("/profile")}>Account</button>}

                <button
                    className="nav-btn nav-close-btn"
                    onClick={showNavbar}>
                    <FaTimes color='white' />
                </button>
            </nav>
            <button
                className="nav-btn"
                onClick={showNavbar}>
                <FaBars color='white' />
            </button>
        </header>
    )
}

export default NavForHome


