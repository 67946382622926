import React, { useEffect, useState } from 'react'
import './UserRows.css'
import { useNavigate } from 'react-router-dom'
import { BsPlusCircle, BsInfoCircle, BsFillCheckCircleFill, BsFillHeartFill } from 'react-icons/bs'  //https://react-icons.github.io/react-icons
import { IoIosArrowDropright } from 'react-icons/io'
import { PiUserCircleLight } from 'react-icons/pi'
import { HiOutlineLockClosed } from "react-icons/hi";

// UserRows is when user searches users what is shown
function UserRows({ user }) {

    const navigate = useNavigate()

    const formatNumber = n => {
        if (n < 1e3) return n;
        if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
        if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(2) + "M";
        if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(3) + "B";
        if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
    };

    return (
        <button onClick={() => navigate("/user?id=" + user[0])} className='searchusers_row'>

            <div className='innersearchdiv'>

                {user[1]?.photoUrl === ""
                    ?
                    <PiUserCircleLight size={38} color='white' alt='no profile picture' />
                    :
                    <img loading="lazy" className="search_avatar" src={user[1]?.photoUrl} alt="Premsi user" />
                }

                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <p className='search_username'>{user[1].displayName}</p>
                    {user[1].verified
                        ?
                        <div style={{ marginLeft: 5, height: 15, width: 15, borderRadius: 30, backgroundColor: "white", alignItems: "center", justifyContent: "center" }}>
                            <BsFillCheckCircleFill size={15} color='blue' alt='verified account' />
                        </div>
                        :
                        null
                    }
                </div>

                <p className='search_p'>{user[1].creatorinfo}</p>

            </div>

            {
                /*
                <div className='searchusernumbers'>
                    <p style={{  }}>Followers: {user[1]?.followers}</p>
                    <p style={{  }}>Premium Posts: {user[1]?.amountpremiumposts}</p>
                </div>
                */
            }

            <div style={{ right: 10, display: "flex", alignItems: "center", justifyContent: "center" }}>
                <IoIosArrowDropright size={17} color='#009FFD' alt='verified account' />
            </div>

        </button>
    )
}

export default UserRows

export function AlgoliaGroupRows({ result }) {
    const navigate = useNavigate()

    const formatNumber = n => {
        if (n < 1e3) return n;
        if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
        if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(2) + "M";
        if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(3) + "B";
        if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
    };

    return (
        <button onClick={() => navigate("/group?id=" + result?.objectID)} className='searchusers_row'>

            <div className='innersearchdiv'>

                <img loading="lazy" className="search_avatar" src={result?.media} alt="Premsi user" />

                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <p className='search_username'>{result.grouptitle}</p>
                </div>

                <p className='search_p'>by {result.creatordisplayname}</p>

            </div>

            <div style={{ height: 30, marginRight: 7, width: 30, borderRadius: 15, display: "flex", alignItems: "center", justifyContent: "center" }}>
                <p style={{ color: "#009FDD", textAlign: "center", fontWeight: "bold" }}>{result.price}$</p>
            </div>

            <div style={{ right: 10, display: "flex", alignItems: "center", justifyContent: "center" }}>
                <IoIosArrowDropright size={17} color='#009FFD' alt='verified account' />
            </div>

        </button>
    )
}

export function AlgoliaPMRows({ result }) {
    const navigate = useNavigate()

    const formatNumber = n => {
        if (n < 1e3) return n;
        if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
        if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(2) + "M";
        if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(3) + "B";
        if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
    };

    return (
        <button onClick={() => navigate(`/pm?id=${result?.objectID}`)} className='searchusers_row'>

            <div className='innersearchdiv'>
                <HiOutlineLockClosed size={23} color='#009FDD' alt='private Premsi premium message' />
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <p className='search_username'>{result?.creatordisplayname}</p>
                </div>
                <p className='search_p'>{result?.description}</p>
            </div>

            {result?.amountfiles === null || result?.amountfiles === undefined ? null :
                <div style={{ height: 30, marginRight: 7, width: 30, borderRadius: 15, display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <p style={{ color: "black", textAlign: "center", fontWeight: "bold" }}>{result?.amountfiles} 🏞</p>
                </div>
            }

            <div style={{ height: 30, marginRight: 7, width: 30, borderRadius: 15, display: "flex", alignItems: "center", justifyContent: "center" }}>
                <p style={{ color: "#009FDD", textAlign: "center", fontWeight: "bold" }}>{result?.price}$</p>
            </div>

            <div onClick={() => navigate(`/pm?id=${result?.objectID}`)} style={{ right: 10, display: "flex", alignItems: "center", justifyContent: "center" }}>
                <IoIosArrowDropright size={17} color='#009FFD' alt='verified account' />
            </div>

        </button>
    )
}