import React, { useEffect, useState } from 'react'
import './Rows.css'
import { getDailyTrending, getFeed, getPremiumFeed } from '../../services/f';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../features/currentUser';
import { Oval } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';

function Rows({ title, isLargeRow = false }) {

    let currentUser = useSelector(getCurrentUser).currentUser?.currentUser

    // by default islargerow is false other when specified true as prop
    // with props get things from homescreen
    const [posts, setposts] = useState([]);
    const [isrefreshing, setisrefreshing] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        
        if (auth.currentUser === undefined || auth.currentUser === null) {
            //console.log("user undefined")
            if (title === "Trending") {
                //console.log("load trending")
                setposts([])
                getDailyTrending(undefined).then((posts) => (setposts(posts), setisrefreshing(false)))
                //getFeed(undefined).then((posts) => (setposts(posts), setisrefreshing(false)))
            } else if (title === "Feed") {
                //console.log("load Feed")
                setposts([])
                getFeed(undefined).then((posts) => (setposts(posts), setisrefreshing(false)))
            } else if (title === "Premium") {
                //console.log("user undefined")
                setisrefreshing(false)
                setposts(["nopremium"])
            }
        } else {
            if (title === "Trending") {
                //console.log("load trending")
                setposts([])
                getDailyTrending(currentUser).then((posts) => (setposts(posts), setisrefreshing(false)))
                //getFeed(undefined).then((posts) => (setposts(posts), setisrefreshing(false)))

            } else if (title === "Feed") {
                //console.log("load Feed")
                setposts([])
                getFeed(currentUser).then((posts) => (setposts(posts), setisrefreshing(false)))
            } else if (title === "Premium") {
                if ((currentUser !== null && currentUser !== undefined && currentUser?.premium === true && currentUser?.premiumend > (new Date().getTime() / 1000))) {
                    //console.log("load Premium")
                    setposts([])
                    getPremiumFeed(currentUser).then((posts) => (/*console.log(posts)*/ setposts(posts), setisrefreshing(false)))
                } else {
                    //console.log("has no Premium")
                    setisrefreshing(false)
                    setposts(["nopremium"])
                }
            }
        }
    }, [currentUser]);

    useEffect(() => {
        if (title === "Trending") {
            setisrefreshing(true)
        } else if (title === "Feed") {
            setisrefreshing(true)
        } else if (title === "Premium") {
            if ((currentUser !== null && currentUser !== undefined && currentUser?.premium === true && currentUser?.premiumend > (new Date().getTime() / 1000))) {
                setisrefreshing(true)
            } else {
                setisrefreshing(false)
            }
        }
    }, []);

    /*
    useEffect(() => {
        console.log("array to", [...posts, "seemore"])
    }, [posts]);
*/
    return (
        <div className='row'>
            <h2>{title}</h2>
            <div className='row__posters'>
                {isrefreshing
                    ?
                    <Oval
                        height={25}
                        width={25}
                        color="#009FFD"
                        wrapperStyle={{ width: 40, flex: "display", alignItems: "center", justifyContent: "center" }}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="white"
                        strokeWidth={5}
                        strokeWidthSecondary={5}
                    />
                    :
                    (posts.length === 1 && posts[0] === "nopremium"
                        ?
                        <div className='no_prem_subsc'>
                            <h5 style={{ textAlign: "center", color: "black" }}>Unfortunately you are not subscribed to Premsi+</h5>
                            <h5 style={{ textAlign: "center", marginTop: 10, color: "black" }}>Only 3.49$ per month!</h5>
                            <div style={{ display: "flex", marginTop: 5, alignItems: "center", justifyContent: "center" }}>
                                <button onClick={() => navigate("/profile")} className='learnmorebtn_rows' style={{ border: 0, padding: 10, fontWeight: "bold", marginTop: 5, borderRadius: 5, backgroundColor: "#009FFD", color: "white" }}>Learn More</button>
                            </div>
                        </div>
                        :
                        [...posts, "seemore"].map((post) =>
                        (post === "seemore"
                            ?
                            <div className='seemoreinapp' >
                                <h5 style={{ textAlign: "center",color:"black" }}>You like what you see?</h5>
                                <h5 style={{ textAlign: "center", marginTop: 10,color:"black" }}>See more in our App!</h5>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <button onClick={() => navigate("/app")} className='downloadnowbtn_rows' style={{ border: 0, padding: 10, marginTop: 5, borderRadius: 5, backgroundColor: "#009FFD", fontWeight: "bold", color: "white" }}>Download Now!</button>
                                </div>
                            </div>
                            :
                            <img
                            loading="lazy"
                                onClick={() => (/*console.log("clicked pos")*/ navigate("/post?id=" + post[0]))}
                                className={`row__poster ${isLargeRow && "row__posterLarge"}`}
                                key={post[0]}
                                src={post[1].type === "image" ? post[1]?.media[0] : post[1]?.media[1]}
                                alt={"Premsi post "+post[1]?.description} />
                        ))
                    )
                }
            </div>
        </div>
    )
}

export default Rows
