import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function VerifyEmailComponent() {
    const navigate = useNavigate()

    return (
        <div style={{ height: 40, width: "100%", backgroundColor: "red", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <p onClick={() => navigate("/profile")} style={{ textDecoration: "underline", color: "white", fontSize: 14 }}>For more features verify your email!</p>
        </div>
    )
}
