import React, { useEffect, useState } from 'react'
import './FAQcomponent.css'
import FAQ from '../../screens/Legal/faq/FAQs'

// UserRows is when user searches users what is shown
export default function FAQcomponent() {

    const [faqtype, setfaqtype] = useState(0);

    const [faqsone, setfaqsone] = useState([
        {
            question: 'What is Premsi?',
            answer: 'Premsi is the social media where you can see the more private side of your favorite creator. On Premsi, your favorite creators share more through their normal and premium posts. Through donations or buying their premium messages you can support them on their journey. Join their groups to get even more insights.',
            open: true
        },
        {
            question: 'Is Premsi free?',
            answer: 'Yes',
            open: false
        },
        {
            question: 'What is Premsi+?',
            answer: "Premsi+ is our premium subscription for 3.49$ (website). With it you can see the premium posts of every user so every content. It's a all in one subscription. No need anymore to subscribe to every creators premium individually. You can purchase it in our App or on our website.",
            open: false
        },
        {
            question: 'What are premium posts?',
            answer: 'These are posts through which your favorite creator can earn some money as only people with Premsi+ can see them!',
            open: false
        },
        {
            question: 'How can I fund my account to buy premium messages?',
            answer: "You can buy a premium message on the premsi website directly with paypal or on premsi.com on your profile page you can easily fund your account with paypal or your credit card! More payment options will come.",
            open: false
        },
        {
            question: 'How do I buy digital content from a creator?',
            answer: 'First you need to fund your account over premsi.com and then just go to the App or on the website to your favorite creator and buy it on their profile! On the website you can also buy it immediately without funding your account or even creating one.',
            open: false
        },
        {
            question: "How do I access the digital content I've purchased?",
            answer: 'You can find it either in the chat with the creator you bought it from or on your profile in the App under "Bought Posts" or in your email inbox if bought without an account!',
            open: false
        },
        {
            question: 'How can I report suspicious activities or content/scam?',
            answer: 'Just go on the profile or post and block or report it! We will take them serious.',
            open: false
        }
    ]);

    const [faqstwo, setfaqstwo] = useState([
        {
            question: 'What is Premsi?',
            answer: 'Premsi is the first completely monetizable social media on which you can market and sell your digital content at once! Either you can make money by just posting premium posts that will be funded through Premsi+ or you sell your digital content (pics, vids, pdfs) through premium messages. You can also create paid groups that generate you a monthly subscription. Soon we will also add courses, where you can easily create courses and sell and market them through Premsi and paid podcasts.',
            open: true
        },
        {
            question: 'How does the algorithm suggest your posts in our App and our Website?',
            answer: "We will push every content up until 50-100 likes. The posts will be shown randomly to our users. Depending on the ratio of views to likes,shares and comments it will be pushed more to other users.",
            open: false
        },
        {
            question: 'Which content can I post on Premsi and what can I sell?',
            answer: "On Premsi you can post whatever you like and what you are interested in. Sensitive content must be marked as such during the upload process. You can sell whatever content you'd like to.",
            open: false
        },
        {
            question: 'What can I sell over Premsi',
            answer: "You can sell any type of digital content through premium messages which you can market with normal posts. You can sell pitures, videos, PDFs, Subscription Groups, and soon Courses.",
            open: false
        },
        {
            question: 'How do I become a creator on Premsi?',
            answer: 'Easy, just register either in the App or on premsi.com and immediately start posting and selling content. No special verification is needed!',
            open: false
        },
        {
            question: 'Is Premsi free for a creator?',
            answer: 'Yes, every feature is completely free! We payout 85% of your earnings in the beginning. The more volume you have the more % you get. As we grow we will also lower this fees.',
            open: false
        },
        {
            question: 'How do I set up premium posts?',
            answer: 'During the post uploading process you can set your upload to be a premium post! Through it you earn money from your supporters.',
            open: false
        },
        {
            question: 'How do I price my digital content?',
            answer: 'You can choose the price yourself!',
            open: false
        },
        {
            question: 'How and when do I get paid for my content sales and premium posts?',
            answer: 'You can payout your earnings through the App via Paypal (soon crypto). Go to the settings and go to "Create Payout". In 2 clicks you can request the payout. Due to Paypal the payout can take up to 3 business days.',
            open: false
        }
    ]);

    const toggleFAQone = index => {
        setfaqsone(faqsone.map((faq, i) => {
            if (i === index) {
                faq.open = !faq.open
            } else {
                faq.open = false;
            }

            return faq;
        }))
    }

    const toggleFAQtwo = index => {
        setfaqstwo(faqstwo.map((faq, i) => {
            if (i === index) {
                faq.open = !faq.open
            } else {
                faq.open = false;
            }

            return faq;
        }))
    }

    return (
        <div className='faqouter'>

            <h2>Frequently asked questions 😇</h2>

            <div style={{ marginTop: 20, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row" }}>
                <div onClick={() => setfaqtype(0)} className='selectdiv' style={{ marginRight: 30, backgroundColor: (faqtype === 0 ? "#009ffd" : "#85cbf4") }}>Fans</div>
                <div onClick={() => setfaqtype(1)} className='selectdiv' style={{ backgroundColor: (faqtype === 0 ? "#85cbf4" : "#009ffd") }}>Creators</div>
            </div>

            {faqtype === 0 ?
                <div className="faqsi">
                    {faqsone.map((faq, i) => (
                        <FAQ key={i} faq={faq} index={i} toggleFAQ={toggleFAQone} />
                    ))}
                </div>
                :
                <div className="faqsi">
                    {faqstwo.map((faq, i) => (
                        <FAQ key={i} faq={faq} index={i} toggleFAQ={toggleFAQtwo} />
                    ))}
                </div>}

        </div>
    )
}



