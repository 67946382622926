import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState: {
    currentUser: null
  },
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    submituser: (state, action) => {
      state.currentUser = action.payload
    },
    nouser: (state) => {
      state.currentUser = null
    }
  },
});

export const { submituser, nouser } = currentUserSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const getCurrentUser = (state) => state.currentUser
//!!!! IM STORE MUSS USER STEHEN ALS REDUCERNAME
export default currentUserSlice.reducer;